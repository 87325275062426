/* eslint-disable no-console */
import { $api } from "../../services/AxiosInstance";
import { AllActions } from "../../store/reducers/AllActions";

export const collectDefaultContent = async (store, params) => {
  const isBlogInnerPage = params?.tag === "bloginner";
  const newParams = () => {
    if (isBlogInnerPage) {
      return { articleId: params?.alias };
    }
    return params?.params;
  };
  let identifier = "";
  if (params.tag === "proxy-site-promo") {
    [, , identifier] = params?.pathname.split("/").reverse();
  } else {
    [, identifier] = params?.pathname.split("/").reverse();
  }

  const fetchParams = {
    ...params,
    languageCode: params?.locale,
    params: newParams(),
    location: params?.location,
    pathname: isBlogInnerPage ? null : params?.pathname
  };

  if (params.tag.includes("proxy-site")) {
    fetchParams.params.siteId = identifier;
  }
  const requests = [
    $api.post("/api/page/front/content", fetchParams),
    $api.get("/api/translation/all"),
    $api.get("/api/front/proxy/type/all"),
    $api.get("/api/front/all/country"),
    $api.get("/api/admin/currency/get/all"),
    $api.get("/api/admin/rent/get/all"),
    $api.get("/api/front/site/proxy/comparison_widget"),
    $api.get("/api/front/site/promo/alternate"),
    $api.get("/api/front/site/reviews/last"),
    $api.get("/api/front/site/top"),
    $api.get("/api/front/social"),
    $api.get("/api/front/captcha"),
    $api.get("/api/front/property/main/block/list"),
    $api.get("/api/system/properties"),
    $api.get("/api/front/site/blog/sidebar", {
      params: { languageCode: params.locale }
    }),
    $api.get("/api/front/site/status"),
    $api.get("/api/front/proxy/type/all", {
      params: { sort: "menuSort" }
    }),
    $api.get("/api/front/property/html"),
    $api.get("/api/front/tools")
  ];
  // eslint-disable-next-line no-undef
  await Promise.all(requests)
    .then((responses) => {
      const [
        pageContent,
        translation,
        proxyTypes,
        countries,
        currencies,
        rentPeriods,
        comparisonWidgetInfo,
        alternatePromocodes,
        reviews,
        sellers,
        socials,
        captchaConfig,
        mainBlockList,
        seoDataDefault,
        sidebar,
        siteStatuses,
        proxyTypesMenu,
        seoDataHtml,
        settings
      ] = responses;
      if (pageContent?.data) {
        store.dispatch(
          AllActions.setPageContent({
            data: pageContent.data,
            lang: params.locale,
            innerTag: params.location
          })
        );
      }
      if (translation.data) {
        store.dispatch(
          AllActions.setLanguages(
            translation.data.map((lang) => ({
              label: lang.languageNameOriginal,
              value: lang.languageCode,
              id: lang.id
            }))
          )
        );
      }
      if (proxyTypes?.data) {
        store.dispatch(AllActions.setProxyTypes(proxyTypes.data));
      }

      if (countries?.data) {
        store.dispatch(AllActions.setCountries(countries.data));
      }

      if (currencies?.data) {
        store.dispatch(AllActions.setCurrencies(currencies.data));
      }

      if (rentPeriods?.data) {
        store.dispatch(AllActions.setRentPeriods(rentPeriods.data));
      }

      if (comparisonWidgetInfo?.data) {
        store.dispatch(
          AllActions.setComparisonWidgetInfo(comparisonWidgetInfo.data)
        );
      }

      if (alternatePromocodes?.data) {
        store.dispatch(
          AllActions.setAlternatePromocodes(alternatePromocodes.data)
        );
      }

      if (reviews?.data) {
        store.dispatch(AllActions.setReviews(reviews.data));
      }

      if (sellers?.data) {
        store.dispatch(AllActions.setSellers(sellers.data));
      }

      if (captchaConfig?.data) {
        store.dispatch(AllActions.setCaptchaConfig(captchaConfig.data));
      }

      if (socials?.data) {
        store.dispatch(AllActions.setSocials(socials.data));
      }

      if (mainBlockList?.data) {
        store.dispatch(AllActions.setMainBlockList(mainBlockList.data));
      }
      if (seoDataDefault?.data) {
        store.dispatch(AllActions.setSeoDataDefault(seoDataDefault.data));
      }
      if (sidebar?.data) {
        store.dispatch(
          AllActions.setBlogSidebarContent({
            data: sidebar.data,
            languageCode: params.locale
          })
        );
      }
      if (siteStatuses?.data) {
        store.dispatch(AllActions.setCabinetSiteStatuses(siteStatuses.data));
      }
      if (proxyTypesMenu?.data) {
        store.dispatch(AllActions.setProxyTypesMenu(proxyTypesMenu?.data));
      }
      if (seoDataHtml?.data) {
        store.dispatch(AllActions.setSeoDataHtml(seoDataHtml?.data));
      }
      if (settings?.data) {
        store.dispatch(AllActions.setToolsSettings(settings?.data));
      }

      store.dispatch(
        AllActions.setRouteContent({
          ...params,
          path: params?.pathname,
          isContainerFluid: false,
          isHeaderFull: false
        })
      );
    })
    .catch(() => {
      console.log("error collecting state!!!");
    });
};
