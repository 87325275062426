export const navigation = [
  {
    route: "addsite",
    translation: "addsite",
    isTopNavigation: true
  },
  {
    route: "feedback",
    translation: "feedback",
    isTopNavigation: true
  },
  {
    route: "blog/?category=all",
    translation: "blog",
    isTopNavigation: true
  }
];

export const navigationFooter = [
  {
    route: "dashboard/services/create",
    translation: "addsite"
  },
  {
    route: "feedback",
    translation: "feedback"
  },
  {
    route: "blog",
    search: "?category=all",
    translation: "blog"
  }
];

export const servicesList = [
  {
    route: "proxy",
    search: "?fpt=IPv4",
    translation: "ipv4",
    description: true,
    useCountries: true
  },
  {
    route: "proxy",
    search: "?fpt=IPv6",
    translation: "ipv6",
    description: true
  },
  {
    route: "proxy",
    search: "?fpt=MobileProxy",
    translation: "mobileProxy",
    description: true
  },
  {
    route: "proxy",
    search: "?fpt=ResidentialProxy",
    translation: "residentialProxy",
    chip: "new",
    description: true
  },
  {
    route: "proxy",
    search: "?fpt=SharedProxy",
    translation: "sharedProxy",
    description: true
  }
];

export const navigationHeader = [
  // {
  //   title: "tools",
  //   list: [
  //     {
  //       route: "tools/free-proxy",
  //       chip: "new",
  //       translation: "freeProxy",
  //       description: true
  //     },
  //     {
  //       route: "tools/fingerprint",
  //       translation: "browserFingerprint",
  //       description: true
  //     },
  //     {
  //       route: "tools/port-scanner",
  //       translation: "portScanner",
  //       description: true
  //     },
  //     {
  //       route: "tools/tracing-ip",
  //       translation: "tracingIP",
  //       description: true
  //     },
  //     {
  //       route: "tools/dns-leak-test",
  //       translation: "dndLeakTest",
  //       description: true
  //     },
  //     {
  //       route: "tools/ip-blacklist-check",
  //       translation: "ipBlacklist",
  //       description: true
  //     },
  //     {
  //       route: "tools/my-anonymity",
  //       translation: "myAnonymity",
  //       description: true
  //     },
  //     {
  //       route: "tools/webrtc-leak-test",
  //       translation: "webRTC",
  //       description: true
  //     },
  //     {
  //       route: "tools/http-headers-checker",
  //       translation: "httpHeaders",
  //       description: true
  //     },
  //     {
  //       route: "tools/evercookie-test",
  //       translation: "evercookieTest",
  //       description: true
  //     },
  //     {
  //       route: "tools/browser-information",
  //       translation: "browserInformation",
  //       description: true
  //     }
  //   ]
  // },
  {
    title: "resources",
    list: [
      {
        route: "blog",
        search: "?category=all",
        translation: "blog"
      },
      {
        route: "feedback",
        translation: "feedback"
      }
    ]
  }
];

export const locationsList = [
  {
    translation: "topLocations"
  },
  {
    translation: "europe",
    continent: "EUROPE"
  },
  {
    translation: "asia",
    continent: "ASIA"
  },
  {
    translation: "northAmerica",
    continent: "NORTH_AMERICA"
  },
  {
    translation: "southAmerica",
    continent: "SOUTH_AMERICA"
  },
  {
    translation: "africa",
    continent: "AFRICA"
  },
  {
    translation: "australiaOceania",
    continent: "AUSTRALIA"
  }
];
