export default {
  header: {
    logo: {
      imgAlt: "Rating Proxy - Independent rating of proxy servers",
      linkTitle: "Go to the main page"
    },
    burger: {
      ariaOpen: "Open menu",
      ariaClose: "Close Menu"
    },
    cabinet: {
      enter: "Login",
      leave: "Logout",
      cabinet: "Sign in"
    },

    bottom: {
      geo: {
        title: "Proxy by geo"
      },
      for: {
        title: "Proxy for"
      },
      vpn: {
        title: "VPN",
        countries: {
          title: "By country "
        },
        devices: {
          title: "For device"
        },
        uses: {
          title: "FOR USE"
        }
      },
      type: {
        title: "Proxy Type",
        ipv4: "Proxy IPv4",
        ipv6: "Proxy IPv6",
        mobile: "Mobile proxy",
        free: "Free proxies"
      },
      accounts: {
        title: "Accounts",
        countries: {
          title: "By country "
        },
        devices: {
          title: "For device"
        },
        uses: {
          title: "FOR USE"
        }
      },
      showMore: "Показать ещё"
    }
  },
  footer: {
    logo: {
      imgAlt: "Rating Proxy - Independent rating of proxy servers",
      linkTitle: "Go to the main page",
      description:
        "Choosing where to buy personal proxies for parsing, arbitration or bypass blocking"
    },
    form: {
      title: "Subscription",
      inputPlaceholder: "Enter email",
      btn: "Send",
      langTitle: "Language:"
    }
  },
  table: {
    cell: {
      period: {
        eternal: "∞ Is Eternal"
      }
    }
  },
  pagination: {
    table: {
      from: "from",
      rowsCount: "items/page"
    }
  },
  metaPage: {
    title: "TrustyTech - Independent Rating Proxy Servers",
    description:
      "More than 100 proxies of services that will provide stable work on social networks, SEO and marketing, rates and online games."
  },
  recentReviews: { title: "Recent reviews", all: "All reviews" },

  mainPage: {
    proxy: {
      titles: {
        IPv4: "Rating of IPv4 proxy providers",
        MobileProxy: "Rating of mobile proxy providers",
        ResidentialProxy: "Rating of residential proxy providers",
        ISP: "Rating of ISP proxy providers",
        IPv6: "Rating of IPv6 proxy providers",
        FreeProxy: "Rating of free proxy providers",
        SharedProxy: "Rating of shared proxy providers",
        RemoteServer: "Rating of remote server providers"
      },
      description:
        "More than 1368 Proxy servers in our rating. Find companies you can trust"
    },
    userChoice: {
      title: "User choice",
      columns: {
        name: "Name",
        price: "Price",
        offers: "Offers",
        site: "Site",
        new: "New"
      }
    },
    determineIp: {
      yourIp: "Your IP",
      notCountry: "Country not defined",
      changeLocat: "Change location",
      changeBtn: "Change"
    },
    title: "Independent rating Proxy Servers",
    subtitle: {
      startLine: "Proxy services for",
      colorLine: "any",
      endLine: "of your tasks"
    },
    highlightWord: "rating",
    description:
      "More than 100 proxies of services that will provide stable work on social networks, SEO and marketing, rates and online games."
  },
  blogPage: {
    breadcrumbs: [
      {
        title: "Blog"
      }
    ],
    title: "Blog",
    tabs: [
      {
        name: "all",
        title: "All"
      },
      {
        name: "articles",
        title: "Articles"
      },
      {
        name: "news",
        title: "News"
      },
      {
        name: "informational",
        title: "Informational"
      },
      {
        name: "browser",
        title: "Browser"
      },
      {
        name: "proxyIPv4",
        title: "ProxyIPv4"
      },
      {
        name: "social media",
        title: "Social Media"
      },
      {
        name: "integrations",
        title: "Integrations"
      },
      {
        name: "proxies",
        title: "Proxies"
      }
    ],
    tabsTitle: "Categories:",
    emptyArticles: "There are no articles",
    emptyNews: "There are no news",
    item: {
      categoryTitle: "Category",
      categories: {
        article: "Article",
        news: "News"
      },
      commentsTitle: "Comments",
      readMore: "Read more",
      popular: "Popular article"
    },
    latest: "The latest",
    search: "Search article"
  },
  blogInnerPage: {
    breadcrumbs: [
      {
        route: "blog/?category=all",
        title: "Blog"
      }
    ],
    articleBody: {
      commentsTitle: "Comments",
      viewsTitle: "views",
      shareTitle: "Was a useful article? Tell us about her familiar",
      shareBtns: {
        vk: "Share with Vkontakte",
        twitter: "Share with Twitter",
        telegram: "Share with Telegram",
        linkedin: "Share with LinkedIn",
        facebook: "Share with Facebook"
      },
      inThisArticle: "In this article:",
      relatedTitle: "Related reading",
      prev: "Previous",
      next: "Next"
    },
    comments: {
      noData: "No comments"
    },
    sidebar: {
      latest: {
        title: "LATEST ADDED ARTICLES"
      },
      popular: {
        title: "Popular Articles"
      },
      more: "Read more"
    }
  },
  addsitePage: {
    title: "Add site",
    breadcrumbs: [
      {
        title: "Add site"
      }
    ],
    tabs: [
      {
        name: "proxy",
        title: "Proxy service"
      }
    ],
    tabsTitle: "Category:"
  },
  proxyPage: {
    proxy: {
      titles: {
        IPv4: "Pick up an IPv4 proxy service connection",
        MobileProxy: "Pick up a mobile proxy service connection",
        ResidentialProxy: "Pick up a residential proxy service connection",
        ISP: "Pick up an ISP proxy service connection",
        IPv6: "Pick up an IPv6 proxy service connection",
        FreeProxy: "Pick up a free proxy service connection",
        SharedProxy: "Pick up a shared proxy service connection",
        RemoteServer: "Pick up a remote server service connection"
      },
      description:
        "More than 1368 Proxy servers in our rating. Find companies you can trust"
    },
    sidebar: {
      promocodes: {
        title: "Promocodes"
      },
      left: {
        countries: {
          title: "Proxy by geo"
        },
        goals: {
          title: "Purpose of use",
          items: {
            socialNetwork: "For social networks",
            onlineGames: "Online games",
            searchEngineParsing: "Search engine parsing",
            other: "Other"
          }
        }
      },
      right: {
        comparison: {
          title: "Comparison",
          compareAction: "Compare",
          clearAction: "Clear"
        },
        sellers: {
          title: "Top sellers",
          promocode: "Promocode for you",
          goBtn: "Go"
        },
        reviews: {
          title: "Last reviews"
        }
      }
    },
    specialOffer: {
      title: "Special offer",
      description:
        "More than 100 proxies of services that will provide stable work on social networks, SEO and marketing, rates and online games."
    },
    content: {
      top: {
        proxy: "Request for proxies",
        title: {
          main: "Rating of proxy services",
          divider: "of"
        }
      },
      filter: {
        title: "Proxy type:",
        rating: "Rating",
        review: "Reviews",
        cost: "Price"
      },
      sort: {
        title: "Sort by"
      },
      table: {
        title: {
          services: "services"
        },
        index: {
          top: "TOP"
        },
        name: {
          name: "Name",
          domainYear: "Domain registration",
          domainYearEnding: "y.",
          countriesQuantity: "Number of countries for sale",
          promocode: "Promocode"
        },
        rating: {
          name: "Rating",
          notRated: "Not Rated",
          halfStar: "Half star",
          oneStar: "One star",
          oneHalfStars: "One & Half stars",
          twoStars: "Two stars",
          twoHalfStars: "Two & Half stars",
          threeStars: "Three stars",
          threeHalfStars: "Three & Half stars",
          fourStars: "Four stars",
          fourHalfStars: "Four & Half stars",
          fiveStars: "Five stars",
          stars: "stars"
        },
        description: {
          title: "Description",
          noDescription: "No description"
        },
        advantages: "Advantages",
        type: {
          title: "Type",
          noContent: "No types"
        },
        freeTest: {
          title: "Free test",
          yes: "Yes",
          no: "No"
        },
        characteristics: {
          title: "Characteristics",
          autorization: {
            title: "Authorization",
            login: "Login+password",
            ip: "By main IP",
            noAuth: "Without authorization"
          }
        },
        geo: {
          title: "GEO",
          more: "more",
          noContent: "No countries",
          modal: {
            title: "All countries",
            search: "Search",
            subtitles: {
              other: "Other countries"
            }
          }
        },
        minPrice: {
          title: "Min. price"
        },
        minRent: {
          title: "Min. rent"
        },
        pp: {
          title: "AP",
          noData: "-"
        },
        api: {
          title: "API",
          newTitle: "FREE TEST",
          yes: "Yes",
          no: "No"
        },
        site: {
          title: "Site",
          btn: "Go",
          readOverview: "Read overview"
        },
        compare: {
          title: "Compare",
          ariaLabel: "Add to comparison"
        },
        top: "Top service",
        noDataComponent: "No data"
      },
      modal: {
        title:
          "Select the required data and we will send a request to all companies in our catalog that match the categories",
        quantity: {
          placeholder: "Quantity"
        },
        btn: "Send"
      }
    }
  },
  proxySitePage: {
    info: {
      tabs: {
        info: "Information",
        social: "Social Media",
        contacts: "Contacts"
      },
      base: {
        domain: "Domain",
        rating: "Rating",
        reviews: "Reviews",
        reviewCount: "reviews",
        reviewCountTwo: "reviews",
        reviewCountOne: "review"
      },
      informations: {
        title: "Services",
        minRent: "Min rental",
        minPrice: "Min price",
        pullIP: "Pull IP",
        million: "m",
        thousand: "k",
        minGB: "Min GB",
        minIP: "Min IP",
        individual: "Individual"
      },
      socialMedia: {
        title: "Social Media"
      },
      contacts: {
        title: "Contacts"
      }
    },
    about: {
      title: "About",
      visit: "Visit website",
      readReview: "Read review",
      info: {
        title: "Informations",
        countries: "Countries",
        allLocations: "All locations",
        speed: "Speed",
        ping: "Ping",
        auth: "Authorization",
        api: "API",
        reviewTitle: "Recent reviews",
        allReviews: "All reviews",
        pingTooltip: "Approximate ping metrics guaranteed by the service",
        freeTest: "Free test",
        affiliate: "Affiliate program",
        refund: "Refund",
        speedTooltip: "Approximate speed metrics guaranteed by the service"
      },
      reviews: {
        title: "Write a review",
        btn: "Add review",
        basedOn: "based on reviews for the last six months"
      },
      rating: {
        title: "Rating graph",
        color: "Rating"
      },
      tabs: {
        info: "Info",
        reviews: "Reviews",
        rating: "Rating graph",
        promocodes: "Promocodes"
      },
      promocodes: {
        title: "Promocodes",
        subscribe: "Subscribe to promocodes"
      }
    },
    content: {
      top: {
        btn: "Go to the site"
      },
      tabs: [
        {
          title: "Characteristics",
          name: "characteristics"
        },
        {
          title: "Reviews",
          name: "reviews"
        },
        {
          title: "Rating graph",
          name: "rating"
        },
        {
          title: "Promocodes",
          name: "promocodes"
        },
        {
          title: "Contacts",
          name: "contacts"
        }
      ],
      contacts: {
        noData: "No contacts"
      },
      chacracteristics: {
        market: {
          title: "On the market"
        },
        rating: {
          title: "Rating"
        },
        countries: {
          title: "Countries"
        },
        freeTest: {
          title: "Free test"
        },
        individual: {
          title: "Individual"
        },
        change: {
          title: "Ability to replace"
        },
        type: {
          title: "Type"
        },
        minCost: {
          title: "Minimum cost"
        },
        minRent: {
          title: "Minimum rental period"
        },
        reviews: {
          title: "Reviews"
        },
        goals: {
          title: "Use goals"
        },
        auth: {
          title: "Authorization",
          login: "Login+password",
          ip: "By main IP",
          noAuth: "Without authorization"
        },
        return: {
          title: "Refund"
        },
        partnership: {
          title: "Affiliate program"
        },
        partnershipPercent: {
          title: "Affiliate percent"
        },
        services: {
          title: "Check the best alternative services"
        },
        description: {
          title: "Description",
          noDescription: "No description"
        },
        alternative: {
          title: "Alternative services"
        }
      },
      rating: {
        labels: [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec"
        ],
        label: "Rating",
        filters: {
          month: "Last month",
          year: "Last year",
          quarter: "Last quarter",
          general: "General"
        }
      },
      reviews: {
        top: {
          title: "Sort by",
          sortList: [
            {
              label: "Usefulness",
              param: "useful"
            },
            {
              label: "Date",
              param: "creationDate"
            },
            {
              label: "Rating",
              param: "rating"
            }
          ]
        },
        noData: "No reviews"
      },
      complaints: {
        top: {
          title: "Sort by",
          sortList: [
            {
              label: "date",
              param: "creationDate"
            }
          ]
        },
        noData: "No complaints"
      },
      promocodes: {
        title: "Promocode",
        btn: "Show promocode",
        noData: {
          title:
            "Active promotional code service {{value}} has not been added yet",
          description:
            "If you are the owner of the service, please log in and create a promocode",
          titleAlternative: "Promocodes of alternative services"
        },
        noDescription: "No description"
      }
    },
    notFound: "Site is not found"
  },
  comparisonPage: {
    title: "Comparison Table",
    table: {
      name: "Name",
      link: "Link to the site",
      rating: "Rating",
      freeTest: "Free test",
      individual: "Individual",
      replacement: "Ability to replace",
      countries: "Countries",
      type: "Type",
      purposes: "Purposes of use",
      minCost: "Minimal cost",
      minRent: "Minimal rent period",
      auth: "Authorization",
      refunds: "Refunds",
      partnership: "Affiliate programm",
      reviews: "Reviews",
      remove: "Remove"
    },
    noData: "Сomparison no longer exists!",
    returnText: "Go back to the main page"
  },
  authPages: {
    navigate: {
      toLogin: {
        text: "Already have an account?",
        btn: "Log in"
      },
      toRegister: {
        text: "Don't have an account?",
        btn: "Sign up"
      }
    },
    agree: {
      with: "I’m agree to the",
      offer: "Offer",
      privacy: "Privacy Policy",
      and: "and"
    },
    signIn: {
      title: "Sign in",
      btn: "Sign in",
      remember: "Remember me",
      forgot: "Forgot password"
    },
    resetPassword: {
      title: "Reset password",
      subtitle: "We'll email you instructions on how to reset your password.",
      success: "Password reset link sent to your email",
      error:
        "We can't find a user with that email address. Try ente another email  or check that the email you entered is correct.",
      btn: "Send password reset link"
    },
    resetPasswordPage: {
      title: "Set new password",
      subtitle: "Enter your new password",
      btn: "Set new password",
      error: "Password reset link is invalid or has expired"
    },

    auth: {
      title: "Login",
      btn: "Login"
    },
    register: {
      title: "Registration",
      btn: "Register"
    },
    registerSuccess: {
      title: "Thank you for registration!",
      text: "We have sent you a confirmation email.",
      spam: "If you haven't received the email, check your folder “Spam”.",
      btn: "Go to dashboard"
    },
    recovery: {
      title: "Account Recovery",
      btn: "Recover"
    },
    feedbackPage: {
      title: "Feedback",
      subtitle:
        "Use this form if you want to ask us a question or report a bug. Please make your message as detailed as possible, then we can solve the problem much faster."
    },
    passRecovery: "Remind password"
  },
  cabinetPages: {
    title: "Personal Area",
    tabs: {
      proxy: "Proxy services",
      complaints: "Complaints",
      promocodes: "Promocodes",
      notifications: "Notifications",
      exit: "Exit"
    },
    proxy: {
      list: {
        new: "Add new service",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
        modalTitle: "Are you sure you want to delete the service?",
        noData: "No services"
      },
      create: {
        title: "Add new proxy service"
      },
      edit: {
        title: "Edit proxy service"
      }
    },
    promocodes: {
      list: {
        new: "Add new promocode",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
        modalTitle: "Are you sure you want to delete the promocode?",
        noData: "No promocodes"
      },
      create: {
        title: "Add promocode"
      },
      edit: {
        title: "Edit promocode"
      }
    },
    breadcrumbs: {
      "cabinet-proxy": [
        {
          title: "Personal Area"
        }
      ],
      "cabinet-proxy-create": [
        {
          title: "Personal Area",
          route: "cabinet/proxy/"
        },
        {
          title: "Creating proxy service"
        }
      ],
      "cabinet-proxy-edit": [
        {
          title: "Personal Area",
          route: "cabinet/proxy/"
        },
        {
          title: "Editing proxy service"
        }
      ],
      "cabinet-promocodes": [
        {
          title: "Personal Area",
          route: "cabinet/proxy/"
        },
        {
          title: "Promocodes"
        }
      ],
      "cabinet-promocodes-create": [
        {
          title: "Personal Area",
          route: "cabinet/proxy/"
        },
        {
          title: "Promocodes",
          route: "cabinet/promocodes/"
        },
        {
          title: "Promocode creating"
        }
      ],
      "cabinet-promocodes-edit": [
        {
          title: "Personal Area",
          route: "cabinet/proxy/"
        },
        {
          title: "Promocodes",
          route: "cabinet/promocodes/"
        },
        {
          title: "Promocode editing"
        }
      ]
    }
  },
  feedbackPage: {
    title: "Leave feedback",
    text: [
      "We are always ready to answer your questions and listen to your suggestions to improve our service.",
      "Use this form if you want to ask us a question or report a bug. Please make your message as detailed as possible, then we can solve the problem much faster."
    ],
    form: {
      btn: "Send"
    },
    success: {
      title: "Thank you for feedback!",
      subtitle: "We will process your response shortly",
      btn: "Go to main page"
    }
  },
  navigation: {
    promotionals: "Promotions",
    addsite: "Add site",
    feedback: "Feedback",
    blog: "Blog",
    logIn: "Log in",
    selectService: "Select service",
    freeProxy: {
      title: "Free proxy",
      description: "Use proxy list absolutely free without authorization"
    },
    browserFingerprint: {
      title: "Browser Fingerprint",
      description: "See your browser fingerprint properties"
    },
    portScanner: {
      title: "Port Scanner",
      description: "Test lists of proxies to avoid potential errors"
    },
    tracingIP: {
      title: "Tracing IP",
      description: "Find connection problems and get more useful data about it"
    },
    dndLeakTest: {
      title: "DNS leak test",
      description: "Check your privacy and reliability"
    },
    ipBlacklist: {
      title: "IP Blacklist Check",
      description: "Use to find out if your IP is blacklisted"
    },
    myAnonymity: {
      title: "My Anonymity",
      description: "Check the degree of anonymity on the network"
    },
    webRTC: {
      title: "WebRTC leak test",
      description: "Protect your personal data by fixing WebRTC leaks"
    },
    httpHeaders: {
      title: "HTTP Headers Checker",
      description: "Provides analysis of the headers of each page of the site"
    },
    evercookieTest: {
      title: "Evercookie test",
      description: "Test cookies and other tracking your website uses"
    },
    browserInformation: {
      title: "Browser Information",
      description: "Find out all the information about your browser"
    },
    ipv4: {
      title: "IPv4 Proxy",
      description: "Dedicated proxy quality with affordability"
    },
    ipv6: {
      title: "IPv6 Proxy",
      description:
        "A package of individual IP addresses with an unlimited validity period"
    },
    mobileProxy: {
      title: "Mobile LTE Proxy",
      description:
        "Take advantage of high-speed rotating mobile 4G proxies with dynamic IP"
    },
    residentialProxy: {
      title: "Residential Proxy",
      description: "Most affordable residential proxies"
    },
    sharedProxy: {
      title: "Shared Proxy",
      description: "Use stable, fast, and furious shared IPs worldwide"
    },
    isp: {
      title: "ISP Proxy"
    },
    remoteServer: {
      title: "Remote Server"
    },
    topLocations: "Top Locations",
    europe: "Europe",
    asia: "Asia",
    northAmerica: "North America",
    southAmerica: "South America",
    australiaOceania: "Australia & Oceania",
    africa: "Africa",
    tools: "Tools",
    resources: "Resources",
    interestingRead: "Interesting to read",
    proxies: "Proxies",
    locations: "Locations",
    services: "Services"
  },
  breadcrumbs: {
    main: "Main"
  },
  proxy: {
    name: {
      label: "Site",
      placeholder: "Site name"
    },
    link: {
      label: "Link to service"
    },
    referralLink: {
      label: "Referal link"
    },
    proxyType: {
      title: "Type"
    },
    countries: {
      title: "Countries"
    },
    goals: {
      title: "Purposes"
    },
    minRent: {
      label: "Minimal rent"
    },
    minPrice: {
      label: "Minimal price"
    },
    auth: {
      title: "Authorization",
      noAuth: "Without authorization",
      loginAuth: "Login + passwrod",
      ipAuth: "By main IP"
    },
    benefits: {
      title: "Benefits",
      moneyBack: "Money back 30 days",
      support: "Support 24/7",
      anonymity: "Anonymity",
      allPlatforms: "All platforms"
    },
    freeTest: {
      label: "Free test"
    },
    refunds: {
      label: "Refunds"
    },
    replacementPossibility: {
      label: "IP replacement"
    },
    api: {
      label: "API"
    },
    affiliateProgram: {
      label: "Affiliate program"
    },
    individual: {
      label: "Individual",
      tooltip: "Select the checkbox if your IPs are provided for individual use"
    },
    promocode: {
      label: "Promocode"
    },
    description: {
      label: "Description"
    },
    socialNetworks: {
      placeholder: "Type your "
    },
    affiliatePercent: {
      label: "Percentage of affiliate program"
    },
    logotype: {
      title: "Logotype",
      btn: {
        delLogo: "Remove logo"
      },
      modal: {
        btn: {
          reset: "Reset",
          save: "Save"
        }
      }
    },
    checkAll: {
      label: "Choose all"
    },
    contacts: {
      title: "Contact details",
      description: "Will be displayed on the page of your service."
    },
    btn: {
      add: "Add service",
      edit: "Edit service"
    }
  },
  promocodes: {
    showCode: "Show promocode",
    subscribe: "Subscribe to new promocodes",

    copy: "Copy",
    site: {
      label: "Site",
      placeholder: "Select site",
      noData: "You don't have any sites yet"
    },
    promocode: {
      label: "Promocode",
      placeholder: "Enter promocode"
    },
    description: {
      label: "Description",
      placeholder: "Description of the promocode"
    },
    eternal: {
      label: "Is Eternal",
      name: "Eternal"
    },
    isActive: {
      label: "Is Active"
    },
    date: {
      label: "Date of action"
    },
    btn: {
      add: "Add promocode",
      edit: "Edit promocode"
    }
  },
  notifications: {
    serverError: "Server error! Please try again later",
    apiError: "Something went wrong!",
    invalidToken: "Invalid token!",
    incorrectData: "Incorrect data!",
    copy: "Copied!",
    copied: "Copied",
    vote: "Thanks for your vote!",
    feedback: "Thanks for your feedback!",
    comment: "Your comment has been accepted. We will publish it soon!",
    proxyQuery: "The proxy request was sent successfully!",
    passwordRecovery: "Password has been successfully changed!",
    emailSent:
      "You have been sent an email with a link to reset your password! Please, chech your email.",
    authError: {
      400: "Invalid token!",
      401: "Wrong login or password!",
      540: "This user is already registered!",
      543: "Captcha error, please try again!",
      560: "User not verified! To confirm, follow the link in the letter in your Email!",
      561: "The user is blocked!",
      545: "Email does not exist! Please enter a valid email!"
    },
    comparison: {
      added: "Site added to compatison!",
      removed: "Site removed from compatison!"
    },
    site: {
      creating: "Site creation",
      created: "Your site has been successfully created!",
      removed: "Your site has been successfully removed!",
      edited: "Your site has been successfully updated!",
      exist: "The site with this link already exists!"
    },
    promocode: {
      created: "Your promocode has been successfully created!",
      removed: "Your promocode has been successfully removed!",
      edited: "Your promocode has been successfully updated!",
      duplicate: "The promocode with this value already exists!"
    },
    image: {
      uploading: "Image uploading",
      uploaded: "Image has been successfully uploaded!"
    },
    file: {
      badFile: "Somethind bad with your file!",
      badFileType: "Type of your file doen't corresponding to .jpeg or .png",
      largeSize: "File size is larger than 2 MB."
    },
    modals: {
      review:
        "Thanks for your review! Your review has been sent for moderation!",
      reviewEdit:
        "Your review has been edited and has been sent for moderation!",
      reviewDelete: "The review has been successfully deleted!",
      complaint:
        "Thanks for your complaint! Your complaint has been sent for moderation!",
      comment: "Your comment has been accepted. We will publish it soon!",
      reviewAlreadySend:
        "Your Email has already left a review for this service!",
      tooOften: "You submit the form too often",
      formBlocked: "Form submission blocked"
    },
    blogInner: {
      notFoundBlogPost: "Blog post not found!"
    }
  },
  dashboard: {
    navBar: {
      profile: "profile",
      services: "services",
      reviews: "reviews",
      promocodes: "promocodes",
      hello: "Hello",
      logout: "Logout",
      addSite: "Add site"
    },
    profile: {
      title: "Profile",
      blockEmail: {
        title: "E-mail address",
        subtitle: "Manage your e-mail address",
        change: "Change e-mail"
      },
      blockPassword: {
        title: "Password",
        subtitle: "Manage your password",
        help: "Minimum 6 characters"
      },
      confirmModal: {
        title: "Confirm changes",
        subtitle: "Please enter special code from your e-mail",
        timer: "Code will be active",
        resend: "Resend code",
        invalidToken: "Invalid token",
        invalidPassword: "Password not match with current",
        invalidEmail: "Please enter valid email",
        emailExist: "This email already exist",
        successEmail: "Email successfully changed",
        successPassword: "Password successfully changed",
        successSend: "We have sent you a confirmation code to your email"
      }
    },
    services: {
      table: {
        title: "Services",
        add: "Add service",
        sort: "Sort by",
        filter: "Filter by",
        all: "All",
        columns: {
          name: "Name",
          date: "Date",
          status: "Status",
          views: "Views",
          reviews: "Reviews",
          conversions: "Conversions",
          actions: {
            title: "Actions",
            edit: "Edit",
            view: "View page",
            delete: "Delete"
          }
        }
      },
      noData: {
        title: "You don't have any open positions yet",
        subtitle: "Add proxy and it will appear in this table."
      },
      modal: {
        delete: {
          title: "Delete service",
          subtitle: "Are you sure delete this proxy service from your rating?"
        }
      },
      addEdit: {
        titleAdd: "Add service",
        titleEdit: "Edit service",
        add: "Add service",
        edit: "Edit service",
        blocks: {
          logo: {
            title: "Company logo",
            subtitle: "Upload file or provide a link below",
            check: "Check",
            download: "Download file and confirm",
            instruction: {
              title: "Instruction",
              variantMeta: "Add meta tag to your site",
              variantFile: "or add the file in the root of the site",
              variantFileExample: "The path to the file should be like this:"
            },
            checkError:
              "We are unable to verify your site. Make sure you have followed the validation instructions and try again.",
            checkSuccess: "Site verification successful!",
            downloadFileError: "Error loading file"
          },
          characteristics: {
            title: "Characteristics",
            subtitle: "Indicate the average value of the services you offer",
            speed: "Speed",
            ping: "Ping",
            speedPlaceholder: "Mb/s",
            pingPlaceholder: "ms",
            from: "From",
            to: "To"
          },
          description: {
            title: "Description",
            subtitle: "Add a short description of the service"
          },
          type: {
            title: "Type",
            subtitle:
              "Select the proxy type and add description of the characteristics",
            advantages: {
              title: "Advantages",
              placeholder: "Anonymity, etc.",
              add: "Add advantage"
            },
            countries: {
              active: "Active countries",
              inactive: "Inactive countries",
              description:
                "These countries are not in our catalog, you can select them. The site moderator will consider the possibility of creating the necessary country"
            }
          },
          auth: {
            title: "Authorization",
            subtitle: "Select authorization type"
          },
          benefits: {
            title: "Benefits",
            subtitle: "Select service benefits"
          },
          features: {
            title: "Features",
            subtitle: "Select service features",
            descriptions: {
              refundDays:
                "Specify the period during which the user can request a refund."
            }
          },
          promocode: {
            title: "Promocode",
            subtitle:
              "Promocode makes it possible to distinguish the service from others and give a discount to the user",
            show: "How does it look?"
          },
          contacts: {
            title: "Contacts",
            subtitle: "Add your contacts and social media"
          }
        }
      }
    },
    promocodes: {
      table: {
        title: "Promocodes",
        add: "Add promocode",
        sort: "Sort by",
        noDataFilter: "No data",

        columns: {
          name: "Name",
          promocode: "Promocode",
          status: "Status",
          date: "Date",
          active: "Active",
          actions: {
            title: "Actions",
            edit: "Edit",
            delete: "Delete"
          }
        }
      },
      activated: {
        success: "Promocode changed active status successfully!",
        notFound: "Promocode not found!",
        startsLater: "Promocode starts later!",
        isExpired: "Promocode is expired!"
      },
      noData: {
        title: "You don't have any promocodes yet",
        subtitle: "Add promocode and it will appear in this table."
      },
      modal: {
        delete: {
          title: "Delete promocode",
          subtitle: "Are you sure delete this promocode?"
        }
      },
      addEdit: {
        titleAdd: "Add promocode",
        titleEdit: "Edit promocode",
        add: "Add promocode",
        edit: "Edit promocode",
        blocks: {
          site: {
            title: "Site",
            subtitle: "Add your site for example www.sitename.com"
          },
          promocode: {
            title: "Promocode",
            subtitle:
              "The promocode is placed on a voluntary basis in order to distinguish the service from others"
          },
          description: {
            title: "Description",
            subtitle:
              "Select input language and add a short description of the promocode"
          },
          period: {
            title: "Validity period",
            subtitle: "Set the validity period for the promocode",
            date: "Date"
          },
          active: {
            description:
              "Please, in order to avoid complications in the use of the promotional code by users, activate the promotional code only when it is active."
          }
        }
      }
    },
    reviews: {
      title: "My reviews",
      add: "Add review",
      appeal: {
        btn: "Appeal",
        modal: {
          title: "Appeal comment"
        }
      },
      noData: {
        title: "You don't have any reviews yet",
        subtitle: "Add review and it will appear in this table."
      },
      sortList: [
        {
          label: "Date",
          value: "CREATION_DATE"
        },
        {
          label: "Rating",
          value: "RATING"
        },
        {
          label: "Usefulness",
          value: "USEFULNESS"
        }
      ],
      status: {
        approved: "Approved",
        rejected: "Rejected",
        moderation: "Moderation"
      },
      statusDescription: {
        approved: "Your review has been approved",
        rejected: "Your review has been rejected",
        moderation: "Your review is under moderation"
      },
      modals: {
        delete: {
          success: "The review has been successfully deleted!",
          error: "Error deleting review!",
          subtitle: "Are you sure delete this review?"
        }
      }
    }
  },
  search: {
    label: "Search",
    placeholder: "Search..."
  },
  forms: {
    refundDays: {
      label: "Refund days",
      placeholder: "Refund days",
      rules: {
        required: "Refund days is required",
        positive: "Refund days must be positive",
        integer: "Refund days must be integer"
      }
    },
    socialLink: {
      rules: {
        https: "Link must start with https:// and doesn't contain spaces"
      }
    },
    reviewBody: {
      label: "Review",
      placeholder: "Write your review",
      rules: {
        required: "Review is required",
        max: "Maximum 100 characters",
        url: "URL in text is not allowed",
        specialCharacters: "Special characters in text is not allowed"
      }
    },
    site: {
      rules: {
        required: "Site is required"
      }
    },
    promocode: {
      label: "Promocode",
      placeholder: "Promocode",
      rules: {
        required: "Promocode is required"
      }
    },
    promocodeDescription: {
      label: "Promocode description",
      placeholder: "Promocode description",
      rules: {
        required: "Promocode description is required",
        minLength:
          "Minimum allowed number of characters in the description: 50!",
        maxLength:
          "Maximum allowed number of characters in the description: 100!"
      }
    },
    date: {
      rules: {
        required: "Date is required",
        allRequired: "Start and end date are required",
        endDateGreaterNow: "The end date must be more than today"
      }
    },
    login: {
      placeholder: "Login",
      rules: {
        required: "Login is required"
      }
    },
    name: {
      label: "Your name",
      rules: {
        required: "Name is required",
        max: "Name cannot be more than 40 symbols"
      }
    },
    link: {
      label: "Link",
      placeholder: "Link",
      rules: {
        required: "Link is required",
        url: "Incorrect link"
      }
    },
    linkCategory: {
      label: "Type of service",
      placeholder: "Choose type of service",
      rules: {
        required: "Type of service is required"
      }
    },
    age: {
      rules: {
        required: "Age is required",
        max: "Maximum age is 99 years"
      }
    },
    proxyType: {
      placeholder: "Choose proxy type",
      rules: {
        required: "Choose type",
        minOne: "Choose at least one type"
      }
    },
    countries: {
      label: "Countries",
      placeholder: {
        one: "Choose country",
        many: "Choose countries"
      },
      rules: {
        required: "Choose countries"
      }
    },
    purposeUse: {
      placeholder: {
        one: "Choose purpose"
      },
      rules: {
        required: "Choose purposes"
      }
    },
    minRent: {
      label: "Minimal rent",
      placeholder: "Choose minimal rent period",
      rules: {
        required: "Choose minimal rent period"
      }
    },
    minPrice: {
      label: "Minimal price",
      placeholder: "Min. price / pcs",
      rules: {
        required: "Enter minimal price",
        max: "Price cannot contain more than 12 symbols",
        positive: "Price must be more than 0"
      }
    },
    pullIP: {
      label: "Pull IP",
      placeholder: "Pull IP",
      rules: {
        required: "Pull IP is required",
        positive: "Pull IP must be more than 0"
      }
    },
    minIP: {
      label: "Min. IP",
      placeholder: "Min. IP",
      tooltip: "The minimum number of IPs that the service allows to purchase",
      rules: {
        required: "Min. IP is required",
        positive: "Min. IP must be more than 0",
        integer: "Min. IP must be an integer"
      }
    },
    minGB: {
      label: "Min. GB",
      placeholder: "Min. GB",
      rules: {
        required: "Min. GB is required",
        positive: "Min. GB must be more than 0"
      }
    },
    currency: {
      placeholder: "Choose currency",
      rules: {
        required: "Choose currency"
      }
    },
    speed: {
      rules: {
        number: "Value must be a number",
        greater: "Value To must be greater than value From",
        positive: "Speed number must be positive"
      }
    },
    ping: {
      rules: {
        positive: "Ping number must be positive"
      }
    },
    description: {
      label: "Description",
      placeholder: "Description",
      rules: {
        required: "Type description",
        minLength:
          "Minimum allowed number of characters in the description: 50!",
        maxLength:
          "Maximum allowed number of characters in the description: 100!",
        maxLength500:
          "Maximum allowed number of characters in the description: 500!",
        notEmpty: "Description cannot be empty"
      }
    },
    allLanguages: {
      rules: {
        allMustBeFilled: "Must be filled for all languages"
      }
    },
    password: {
      placeholder: "Password",
      placeholderRepeat: "Repeat password",
      placeholderCurrent: "Enter current password",
      labelChangeEmail: "Password (if changing email)",
      labelCurrent: "Current password",
      labelNew: "New password",

      newPassword: "Type new password",
      state: {
        show: "Show password",
        hide: "Hide password"
      },
      rules: {
        required: "Password is required",
        min: "Minimum symbols",
        mismatch: "Passwords must match",
        noSpace: "Password cannot contain spaces",
        cannotBeSame: "New password cannot be the same as the old one"
      }
    },
    email: {
      label: "Your email",
      rules: {
        required: "Email is required",
        email: "Incorrect mail"
      }
    },
    emailV2: {
      label: "E-mail",
      placeholder: "Enter your e-mail",
      rules: {
        required: "Email is required",
        email: "Incorrect mail"
      }
    },
    answer: {
      label: "Your answer",
      rules: {
        required: "Answer is required"
      }
    },
    agree: {
      rules: {
        required: "Agree with the rules for continuing"
      }
    },
    affiliatePercent: {
      rules: {
        max: "Percent cannot be more than 3 symbols"
      }
    },
    message: {
      label: "Message",
      placeholder: "Enter text...",
      rules: {
        required: "Fill in the message"
      }
    },
    comment: {
      label: "Comment",
      placeholder: "Type your comment",
      rules: {
        required: "Fill in the comment"
      }
    },
    field: {
      rules: {
        required: "Field is required"
      }
    },
    common: {
      rules: {
        required: "Choose value"
      }
    },
    addComment: {
      btn: "Add comment"
    },
    buttons: {
      submit: "Submit",
      cancel: "Cancel",
      save: "Save",
      delete: "Delete",
      edit: "Edit",
      add: "Add",
      saveChanges: "Save changes",
      confirm: "Confirm",
      send: "Send"
    },
    noOptions: "No options"
  },
  reviews: {
    anonymous: "Anonymous",
    answers: "Answers",
    answer: "Answer",
    showMore: "Show more",
    showFull: "Show full",
    you: "You",
    expert: "Brand expert answer",
    showAll: "Show all answers",
    showLess: "Show less",
    content: {
      advantages: {
        title: "Advantages"
      },
      disadvantages: {
        title: "Disadvantages"
      },
      review: {
        title: "Review"
      },
      usage: {
        title: "Where used"
      },
      wishes: {
        title: "Wishes to the seller"
      },
      reason: {
        title: "Reason"
      }
    },
    reply: {
      apply: "Answer",
      cancel: "Cancel",
      toggle: {
        close: "Collapse",
        open: "Open"
      }
    },
    voting: {
      title: "Vote"
    },
    feedback: {
      title: "Is review helpful"
    },
    rating: {
      title: "Rating",
      from: "from"
    },
    translator: {
      status: {
        translated: "Translated",
        original: "Original"
      },
      showOriginal: "Show original",
      translate: "Translate"
    }
  },
  common: {
    yes: "Yes",
    no: "No"
  },
  validation: {
    valid: {
      title: "Valid",
      report: "Domain is valid!"
    },
    invalid: {
      title: "Invalid",
      report: "Domain invalid!",
      panel: {
        title: "Domain validation for",
        notice: "To verify a domain, use one of the methods below.",
        subtitle: "How to verify a domain",
        link: {
          title: "In the root of the site, add the file uploaded",
          linkName: "here",
          example: "The path to the file should be like this:"
        },
        metag: {
          title: "Or add a meta tag to the site:"
        },
        nextData: {
          title: "Date of next validation:"
        }
      }
    }
  },
  statuses: {
    active: "Active",
    inactive: "Inactive",
    added: "Added",
    title: "Status",
    moderation: "Moderation"
  },
  statistic: {
    views: {
      title: "Views"
    },
    conversions: {
      title: "Conversion"
    },
    reviews: {
      title: "Reviews"
    }
  },
  uploader: {
    chooseFile: "Click to upload",
    chooseFileSecond: "or drag and drop",
    or: "or",
    chooseOtherFile: "Choose other file",
    staticState: "or drag it here. (up to 2 MB)",
    draggingState: "Drop file to upload it",
    format: "SVG, PNG or JPG 256x256 px (max. size 2MB)"
  },
  modals: {
    review: {
      titleAdd: "Leave review",
      titleEdit: "Edit review",
      titleDelete: "Are you sure you want to delete the review?",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      form: {
        ratings: {
          title: "rate the service",
          rating: "Rating",
          speed: "Speed",
          reliability: "Reliability",
          support: "Support",
          price: "Price"
        },
        personalInfo: {
          title: "Personal information",
          name: "Name",
          email: "E-mail"
        },

        advantages: "Advantages",
        disadvantages: "Disadvantages",
        review: "Review",
        usage: "Where used",
        wishes: "Wishes",
        rating: "Choose the rating",
        placeholder: "Enter text",
        success: {
          title: "Thank you!",
          text: "Your review has been sent and will appear on the site after verification by the moderator"
        }
      }
    },
    complaint: {
      title: "Leave complaint",
      form: {
        reason: "Reason",
        body: "Complaint"
      }
    },
    comment: {
      title: "Leave comment",
      form: {
        name: {
          title: "Username",
          placeholder: "Name"
        },
        email: {
          title: "E-mail",
          placeholder: "rating.proxy@mail.com"
        },
        body: {
          title: "Comment",
          placeholder: "Comment text"
        }
      }
    },
    email: {
      title: "Email confirmation",
      confirmed: "Your mail has been successfully confirmed!",
      notConfirmed:
        "Unfortunately, the confirmation of your mail was unsuccessful! Register again."
    },
    subscribe: {
      title: "Subscribe to new promocodes",
      description: "We will send you new promocodes to email",
      success: "You have successfully subscribed to new promocodes!",
      info: "You have already subscribed to new promocodes of this service!",
      form: {
        email: {
          title: "E-mail",
          placeholder: "Enter your e-mail"
        },
        name: {
          title: "Name",
          placeholder: "Enter your name"
        },
        btn: "Subscribe",
        close: "Close"
      }
    }
  },
  yesno: [
    {
      value: true,
      label: "Yes"
    },
    {
      value: false,
      label: "No"
    }
  ],
  times: {
    hour: ["hour", "hours"],
    day: ["day", "days"],
    week: ["week", "weeks"],
    month: ["month", "months"],
    year: ["year", "years"]
  },
  proxyItems: {
    countries: {
      title: "Proxy by countries",
      list: {
        more: "More",
        countries: "countries",
        proxies: "proxy services"
      }
    },
    byType: {
      title: "Proxy by type"
    },
    social: {
      title: "Proxy for social networks",
      additionalText: ["and others"]
    },
    seo: {
      title: "Proxy for SEO",
      additionalText: ["and others"]
    },
    mobile: {
      title: "Mobile proxies",
      list: {
        countries: "countries",
        operators: "operators",
        proxies: "proxy services"
      }
    },
    shared: {
      title: "Shared proxy",
      list: {
        countries: "countries",
        proxies: "proxy services"
      }
    },
    unknownTile: {
      title: "Unknown tile"
    }
  },
  countries: [
    {
      code: "EGY",
      name: "Egypt"
    },
    {
      code: "AUS",
      name: "Australia"
    },
    {
      code: "AUT",
      name: "Austria"
    },
    {
      code: "GBR",
      name: "Great Britain"
    },
    {
      code: "AM",
      name: "Armenia"
    },
    {
      code: "BLR",
      name: "Belarus"
    },
    {
      code: "BGR",
      name: "Bulgaria"
    },
    {
      code: "BRA",
      name: "Brazil"
    },
    {
      code: "VEN",
      name: "Venezuela"
    },
    {
      code: "DEU",
      name: "Germany"
    },
    {
      code: "GEO",
      name: "Georgia"
    },
    {
      code: "EUROPE",
      name: "Europe"
    },
    {
      code: "IND",
      name: "India"
    },
    {
      code: "ESP",
      name: "Spain"
    },
    {
      code: "ITA",
      name: "Italy"
    },
    {
      code: "KAZ",
      name: "Kazakhstan"
    },
    {
      code: "CAN",
      name: "Canada"
    },
    {
      code: "KGZ",
      name: "Kyrgyzstan"
    },
    {
      code: "CHN",
      name: "China"
    },
    {
      code: "KOR",
      name: "South Korea"
    },
    {
      code: "LVA",
      name: "Latvia"
    },
    {
      code: "LTU",
      name: "Lithuania"
    },
    {
      code: "LIE",
      name: "Liechtenstein"
    },
    {
      code: "MEX",
      name: "Mexico"
    },
    {
      code: "MDA",
      name: "Moldova"
    },
    {
      code: "MCO",
      name: "Monako"
    },
    {
      code: "NLD",
      name: "Netherlands"
    },
    {
      code: "NOR",
      name: "Norway"
    },
    {
      code: "POL",
      name: "Poland"
    },
    {
      code: "RUS",
      name: "Russia"
    },
    {
      code: "SYC",
      name: "Seychelles"
    },
    {
      code: "SGP",
      name: "Singapore"
    },
    {
      code: "SVN",
      name: "Salvador"
    },
    {
      code: "USA",
      name: "USA"
    },
    {
      code: "TUR",
      name: "Turkey"
    },
    {
      code: "UKR",
      name: "Ukraine"
    },
    {
      code: "FIN",
      name: "Finland"
    },
    {
      code: "FRA",
      name: "France"
    },
    {
      code: "MNE",
      name: "Montenegro"
    },
    {
      code: "CZE",
      name: "Czech"
    },
    {
      code: "CHL",
      name: "Chile"
    },
    {
      code: "CHE",
      name: "Switzerland"
    },
    {
      code: "SWE",
      name: "Sweden"
    },
    {
      code: "LKA",
      name: "Sri Lanka"
    },
    {
      code: "JAM",
      name: "Jamaica"
    },
    {
      code: "JPN",
      name: "Japan"
    }
  ],
  proxyTypesGen: {
    IPv4: "IPv4",
    IPv6: "IPv6",
    ISP: "ISP",
    FreeProxy: "Free",
    MobileProxy: "Mobile",
    SharedProxy: "Shared",
    ResidentialProxy: "Residential",
    RemoteServer: "Remote"
  },
  // proxy
  ui: {
    readReview: "Read review",
    yesNo: {
      yes: "Yes",
      no: "No"
    },
    export: "Export:",
    step: "Step",
    on: "on"
  },
  fingerprint: {
    main: {
      title: "Browser Fingerprint",
      subtitle: "Are you unique? See your browser fingerprint properties",
      buttonLabel: "Check your fingerprint"
    },
    httpAttributes: "HTTP headers attributes",
    searchAttributes: "Search for an attributes",
    javaScriptAttributes: "JavaScript attributes",
    table: {
      attribute: "Attribute",
      similarityRatio: "Similarity ratio",
      value: "Value",
      and: "And",
      others: "Others",
      noValue: "No value"
    },
    faq: "FAQ",
    HTTP: {
      userAgent: "User Agent",
      userAgentTooltip: "Shows info about the browser and device.",
      accept: "Accept",
      acceptTooltip:
        "The HTTP header indicating the media types that the client is willing to receive from the server.",
      contentEncoding: "Content Encoding",
      contentEncodingTooltip:
        "The HTTP header specifying the encoding transformations that have been applied to the content.",
      contentLanguage: "Content Language",
      contentLanguageTooltip:
        "The HTTP header indicating the language(s) of the content provided by the server.",
      upgradeInsecureRequests: "Upgrade Insecure Requests",
      upgradeInsecureRequestsTooltip:
        "The HTTP header used to indicate that the client prefers to receive secure (HTTPS) versions of the resources.",
      ifNoneMatch: "If none match",
      ifNoneMatchTooltip:
        "The HTTP If-None-Match request header is used to transform the HTTP request into one that is conditional based on a set of HTTP ETag headers not matching.",
      referer: "Referer",
      refererTooltip:
        "Referer is an optional HTTP header field that identifies the address of the web page from which the resource has been requested."
    },
    JS: {
      userAgentJS: "User Agent",
      userAgentJSTooltip: "Shows info about the browser and device.",
      os: "Platform",
      osTooltip: "Software that manages a computer or device.",
      cookie: "Cookies enabled",
      cookieTooltip:
        "Small data stored by a website in the browser. Used to remember user preferences and sessions.",
      timeZone: "Timezone",
      timeZoneTooltip:
        "The region-based time standard used by a device or application.",
      languageJs: "Content language",
      languageJsTooltip:
        "The programming or scripting language used by the website or application.",
      canvas: "Canvas",
      canvasTooltip:
        "The process of gathering opinions or data, often through surveys or direct interactions.",
      fonts: "List of fonts (JS)",
      fontsTooltip: "List of fonts used in the application (JS).",
      navigatorProperties: "Navigator properties",
      navigatorPropertiesTooltip: "Navigator properties",
      adblock: "Use of Adblock",
      adblockTooltip:
        "A tool or extension that prevents advertisements from being displayed in the browser.",
      doNotTrack: "Do Not Track",
      doNotTrackTooltip:
        "A privacy feature that prevents websites from tracking your browsing activity.",
      buildID: "BuildID",
      buildIDTooltip:
        "A unique identifier for a specific build or version of the application.",
      product: "Product",
      productTooltip:
        "The specific item or service offered by a company or application.",
      productSub: "Product sub",
      productSubTooltip:
        "A subcategory or specific variant of the main product.",
      vendor: "Vendor",
      vendorTooltip:
        "The company or individual that provides or sells a product or service.",
      vendorSub: "Vendor sub",
      vendorSubTooltip:
        "A subcategory or specific variant related to the main vendor.",
      hardware: "Hardware concurrency",
      hardwareTooltip:
        "The number of logical processors or cores available on a device.",
      java: "Java enabled",
      javaTooltip:
        "Indicates whether Java is enabled in the browser or application.",
      memory: "Device memory",
      memoryTooltip: "The amount of RAM available on the device.",
      plugins: "List of plugins",
      pluginsTooltip: "A list of installed browser or application plugins.",
      screenWidth: "Screen Width",
      screenWidthTooltip:
        "The total width of the screen, including all visible areas.",
      screenHeight: "Screen Height",
      screenHeightTooltip:
        "The total height of the screen, including all visible areas.",
      screenDepth: "Screen Color Depth",
      screenDepthTooltip:
        "The number of bits used to represent the color of each pixel on the screen.",
      availableTop: "Available Screen Top",
      availableTopTooltip:
        "The vertical distance from the top of the screen to the top of the area available for content, excluding any system elements.",
      availableLeft: "Available Screen Left",
      availableLeftTooltip:
        "The horizontal distance from the left edge of the screen to the left edge of the area available for content, excluding any system elements.",
      availableHeight: "Available Screen Height",
      availableHeightTooltip:
        "The vertical distance available for content on the screen, excluding any areas occupied by system elements.",
      availableWidth: "Available Screen Width",
      availableWidthTooltip:
        "The horizontal distance available for content on the screen, excluding any areas occupied by system elements.",
      permissions: "Permissions",
      permissionsTooltip:
        "The list of permissions granted or requested by the application or website.",
      webGLVendor: "WebGL Vendor",
      webGLVendorTooltip:
        "The vendor or manufacturer of the WebGL implementation used by the browser or application.",
      webGLRenderer: "WebGL Renderer",
      webGLRendererTooltip:
        "The specific renderer or graphics engine used by WebGL to render graphics in the browser or application.",
      webGLData: "WebGL Data",
      webGLDataTooltip:
        "Information about the WebGL context and the data being `processed or used for rendering.",
      webGLParameters: "WebGL Parameters",
      webGLParametersTooltip:
        "Configuration settings and parameters for the WebGL context, including details about rendering and performance.",
      useOfLocalStorage: "Use of local storage",
      useOfLocalStorageTooltip:
        "Indicates whether the application or website utilizes the browser's local storage for saving data.",
      useOfSessionStorage: "Use of session storage",
      useOfSessionStorageTooltip:
        "Indicates whether the application or website utilizes the browser's session storage for saving data temporarily.",
      useOfIndexedDB: "Use of IndexedDB",
      useOfIndexedDBTooltip:
        "Indicates whether the application or website utilizes IndexedDB for storing large amounts of structured data.",
      audioFormats: "Audio formats",
      audioFormatsTooltip:
        "A list of audio formats supported or used by the application or website.",
      audioContext: "Audio context",
      audioContextTooltip:
        "An interface used to manage and control the audio processing and routing within an application or website.",
      frequencyAnalyser: "Frequency analyser",
      frequencyAnalyserTooltip:
        "A tool or feature that analyzes and displays the frequency components of audio signals.",
      audioData: "Audio data",
      audioDataTooltip:
        "The raw or processed data representing audio signals, used for playback or analysis.",
      videoFormats: "Video formats",
      videoFormatsTooltip:
        "A list of video formats supported or used by the application or website.",
      mediaDevices: "Media devices",
      mediaDevicesTooltip:
        "A list of media devices such as cameras, microphones, and speakers available to the application or website.",
      accelerometer: "Accelerometer",
      accelerometerTooltip:
        "A sensor that measures the acceleration forces acting on a device to detect motion and orientation.",
      gyroscope: "Gyroscope",
      gyroscopeTooltip:
        "A sensor that measures the rate of rotation around the device's axes to detect orientation and angular velocity.",
      proximitySensor: "Proximity sensor",
      proximitySensorTooltip:
        "A sensor that detects the presence of nearby objects without physical contact, typically used to determine proximity to the device.",
      keyboardLayout: "Keyboard layout",
      keyboardLayoutTooltip:
        "The arrangement of keys on a keyboard, including the type of layout used (e.g., QWERTY, AZERTY).",
      battery: "Battery",
      batteryTooltip: "Information about the device's battery status",
      connection: "Connection",
      connectionTooltip:
        "Information about the network connection type and status, such as Wi-Fi, cellular, or Ethernet.",
      key: "Key",
      keyTooltip:
        "A unique identifier or piece of information used to access or interact with data or services.",
      locationBar: "Location bar",
      locationBarTooltip:
        "The part of the browser's interface where the URL of the current webpage is displayed and can be edited.",
      menuBar: "Menu bar",
      menuBarTooltip:
        "The part of the application or browser interface that contains menus for accessing various functions and settings.",
      personalBar: "Personal bar",
      personalBarTooltip:
        "A customizable area in the browser interface where users can add shortcuts to their favorite sites or tools.",
      statusBar: "Status bar",
      statusBarTooltip:
        "The area of the browser or application interface that displays information about the current status, such as loading progress or notifications.",
      toolBar: "Tool bar",
      toolBarTooltip:
        "A section of the application or browser interface that provides access to various tools and functionalities, often with buttons or icons.",
      resultState: "Result state",
      resultStateTooltip: "The current status or condition of the result",
      listOfFontsFlash: "List of fonts (Flash)",
      listOfFontsFlashTooltip:
        "A list of fonts available in Adobe Flash or Flash Player, which may include both",
      screenResolutionFlash: "Screen resolution (Flash)",
      screenResolutionFlashTooltip:
        "The screen resolution settings used by Adobe Flash or Flash Player, specifying the width and height of the display area.",
      languageFlash: "Language (Flash)",
      languageFlashTooltip:
        "The language settings used by Adobe Flash or Flash Player, indicating the language of the content or user interface.",
      platformFlash: "Platform (Flash)",
      platformFlashTooltip:
        "The platform or operating system settings used by Adobe Flash or Flash Player, indicating the environment where Flash is running."
    }
  },
  portScanner: {
    main: {
      title: "Port scanner online",
      subtitle:
        "Port Checker is a simple and free online tool for checking open ports on your computer/device, often useful in testing port forwarding settings on a router."
    },
    testPort: "Test your port:",
    form: {
      loading: "Loading",
      scan: "Scan",
      pasteIP: "Paste my IP address",
      inputLabel: "IP address or domain:",
      selectLabel: "Type of port scanning",
      infoMessage:
        "If the result is «Host seems down», then the firewall or router of the target is checked and the IP is blocking pings",
      popularPorts: "Popular ports",
      serverPorts: "Server ports",
      gamePorts: "Game ports",
      applicationPorts: "Application ports",
      p2pPorts: "P2P ports",
      rules: {
        required: "IP address or domain is required",
        ip: "Incorrect IP address or domain"
      }
    },
    checkResults: "Check results:",
    open: "open",
    closed: "closed",
    notShown: "Not shown:",
    ipDomain: "IP/ Domain:",
    portChecked: {
      title: "What ports are checked",
      port: "Port",
      protocol: "Protocol",
      description: "Description",
      tooltip: "Choose in which format you want to export the check results"
    },
    sliderTitle: "What does port scanner online do:",
    cta: {
      title: "Which ports are scanned",
      description:
        "Our service scans the most popular ports and hidden ports. A complete list of them can be seen after checking in the context block on the right.",
      buttonLabel: "Try port scanner online"
    },
    stepGuide: "How to scan ports — step-by-step guide"
  },
  tracingIp: {
    main: {
      title: "Tracing IP online",
      subtitle:
        "Online IP Trace is a diagnostic tool that displays information on all intermediate routers on the way to the end node. To do this, use our online service. You can use this diagnostic tool for free an unlimited number of times."
    },
    form: {
      traceIP: "Trace IP",
      loading: "Loading",
      inputLabel: "Enter a valid IP address (0.0.0.0) or hostname",
      enterIP: "Enter IP",
      selectLabel: "Show package route by country",
      infoMessage: "Tracing can take up to several minutes ",
      rules: {
        required: "IP address or hostname is required",
        ip: "Incorrect IP address or hostname"
      }
    }
  },
  dnsLeak: {
    main: {
      title: "DNS leak test",
      subtitle:
        "The DNS Leak Test is a tool used to determine which DNS servers your browser is using to resolve domain names."
    },
    analyzing: "Analyzing...",
    result: "Result:",
    vulnerable: "VULNERABLE",
    verify:
      "Verify that your Internet traffic is being routed through your chosen DNS",
    notDetected:
      "DNS leak not detected but to be on the safer side, use TrustyTech",
    stopDns: "Stop DNS leak",
    runDns: "Run DNS leak test",
    yourIP: "Your IP is:",
    pleaseWait: "Please wait a few seconds",
    table: {
      ip: "ip",
      provider: "Provider",
      country: "Country"
    },
    whatIsDns: {
      title: "What is a DNS Leak Test",
      description:
        "DNS helps users easily access websites and other internet resources. Your device sends its queries to a DNS server, and the server sends back directions to what you’re looking for. This is what makes DNS a significant privacy risk."
    },
    whatDoesDns: {
      title: "What does the DNS check show",
      description:
        "Checking for DNS leaks helps protect your privacy and ensure that your internet browsing activity is not exposed to unauthorized and potentially malicious parties. You should check for DNS leaks regularly to verify your DNS requests go where they should and that your online activity remains private."
    },
    howFixDns: {
      title: "How to fix a DNS leak"
    }
  },
  ipBlacklist: {
    main: {
      title: "IP Blacklist Check",
      subtitle:
        "As you know, spam can often cause IP to be blacklisted, which noticeably disrupts all network operations. To avoid this, you should use the IP check server in spam databases."
    },
    ipCheckserver: "IP Check server",
    form: {
      examination: "Examination",
      loading: "Loading",
      enterIp: "Enter IP",
      label: "Please enter the correct IP address:",
      infoMessage: "The test may take a while.",
      rules: {
        required: "IP address is required",
        ip: "Incorrect IP address"
      }
    },
    checks: "Checks:",
    checkedOut: "Checked out:",
    blacklisted: "Blacklisted:",
    listed: "Listed",
    notListed: "Not listed"
  },
  freeProxy: {
    main: {
      title: "Free Proxy server online",
      subtitle:
        "We provide lists of free proxy servers for informational purposes. Our regularly updated lists feature free IP addresses from over 150 countries. However, free proxies are not suitable for regular, serious tasks. For such purposes, we recommend exploring our <a href='https://proxy-sale.com/'>personal proxies</a> service."
    },
    list: {
      title: "Free proxy list",
      itemsFound: "Items found:",
      tooltip: "Choose in which format you want to export the proxy list",
      infoMessage:
        "Free proxy lists provided by this service are publicly avaliable data obtained by parsing public Internet pages and are provided for informational purposes only. The use of free proxy lists is carried out solety at your own peril and risk."
    },
    filter: {
      country: "Country",
      selectCountry: "Select country",
      city: "City",
      selectCity: "Select city",
      typeOf: "Type of",
      allCountries: "All countries",
      allCities: "All cities",
      allTypes: "All types"
    },
    table: {
      ipAddress: "IP address",
      port: "Port",
      anonymity: "Anonymity",
      lastChecked: "Last Checked",
      speed: "Speed"
    },
    howUseProxy: {
      title: "How to use a free proxy",
      description:
        "Free proxy became especially popular when there was a massive need to bypass the blocking of popular web resources - social networks, video hosting, and others. ",
      listTitle: "So you can use free proxy for any purpose:"
    },
    differenceBetween: {
      title: "Difference between free and personal proxy servers",
      description:
        "At first glance, personal and free proxy servers are the same, as their working principles are similar: in order to «trick» provider or website security system, the real user’s IP is “covered” by an intermediate IP proxy. However, in fact the difference is rather sharp:",
      freeProxy: "Free proxy",
      personalProxy: "Personal proxy"
    },
    howDownloadProxy: {
      title: "How to download proxy list"
    }
  },
  myAnonymity: {
    main: {
      title: "My Anonymity",
      subtitle:
        "The IP anonymity check service provides comprehensive information that various services or websites might access when visited or used. Collectively, this data can significantly reduce the perceived trustworthiness of an IP address. For thorough anonymity checks, it is advisable to follow the basic recommendations of the service for anonymous and secure operations."
    },
    table: {
      provider: "Provider",
      hostname: "Hostname",
      os: "OS",
      browser: "Browser",
      dns: "DNS",
      proxy: "Proxy",
      anonymizer: "Anonymizer",
      blacklist: "Blacklist"
    },
    myIP: "My IP:",
    title: "Let's start checking anonymity",
    subtitle:
      "After a detailed check, the screen will display all the main items that determine the level of anonymity of your IP",
    buttonLabel: "Check my anonymity",
    yourDisguise: "Your disguise:",
    info: "Moderate security and anonymity remarks",
    details: {
      title: "IP Address Details",
      location: "Location",
      language: "Language",
      browser: "Browser",
      time: "Time",
      socialMedia: "Social Media",
      interactiveDetection: "Interactive detection",
      navigator: "Navigator",
      screen: "Screen",
      scripts: "Scripts",
      plugins: "Plugins",
      hide: "Hide",
      showMore: "Show more",
      enabled: "enabled",
      disabled: "disabled",
      country: "Country",
      region: "Region",
      city: "City",
      zip: "ZIP",
      hostname: "Hostname",
      reversed: "Reversed",
      ipRange: "IP range",
      isp: "ISP",
      organization: "Organization",
      asOrganization: "AS Organization",
      asNumber: "AS Number",
      headers: "Headers",
      zone: "Zone",
      local: "Local",
      system: "System",
      ipAddress: "Ip address",
      ports: "Ports",
      openPorts: "Open ports",
      platform: "Platform",
      height: "Height",
      width: "Width",
      windowSize: "Window size",
      os: "OS"
    },
    whatIsAnonymity: {
      title: "What is anonymity checker for IP or proxies",
      description:
        "With this service, you can check how anonymous you are on the network, how much data provided by your computer/browser, the same data provided by your ip-address. Also, the service can be used to check for vulnerabilities that affect the anonymity of IP."
    },
    whatDoesAnonymity: {
      title: "What does anonymity check do",
      description: `The verification tool accurately determines the anonymity of the proxy, shows its use and the real type of user. Having received such information, the client can either confirm his safety, or (in its absence) provide it to himself as soon as possible.
      Using this service, you can check the degree of anonymity on the network, as well as the fact that the data provided by your computer / browser matches the data provided by your IP address.`
    }
  },
  httpHeadersChecker: {
    main: {
      title: "HTTP Headers Checker",
      subtitle:
        "Test the headers advertised by your web server or network edge device"
    },
    httpHeadersCheck: "HTTP Header Check",
    site: "Site",
    ipAddress: "IP Address",
    form: {
      enterWebsite: "Enter a website URL",
      selectCountry: "Select country",
      button: "Test Headers",
      info: "The test may take a while.",
      rules: {
        required: "Website is required",
        url: "Incorrect url"
      }
    },
    table: {
      header: "Header",
      value: "Value"
    }
  },
  webRTCLeakTest: {
    main: {
      title: "WebRTC leak test",
      subtitle:
        "If WebRTC is turned on in your browser and you’re on a website that uses it, your real IP address will be visible, and, therefore, it will be leaking. Test if WebRTC is enabled in your browser and leaks your real IP address."
    },
    table: {
      ip: "Ip",
      type: "Type",
      status: "Status",
      leak: "Potential leak",
      noLeak: "No leak",
      leakTooltip:
        "Your private information may be compromised, even if you’re using a VPN.",
      noLeakTooltip: "Your personal information is protected"
    },
    webRTCTest: {
      title: "WebRTC exposed IP addresses",
      buttonLabel: "Prevent WebRTC leaks",
      leakMessage:
        "Your browser is exposing IP addresses to sites that you visit.",
      noLeakMessage:
        "Exellent! The WebRTC protocol is disabled, so your IP address is protected."
    },
    whatIsWebRTC: {
      title: "What is WebRTC",
      description:
        "WebRTC or Web Real-Time Communication gives web browsers the power to communicate directly without a third-party server. This means faster speeds and response times when browsing the internet. This is important for things such as live streaming on services like Twitch, or any other service that relies on speed and ping times."
    },
    whatDoesWebRTC: {
      title: "What does WebRTC leak test do",
      description:
        "This tool will tell you if your real public IP addresses are unsecured. You will be able to see the IP addresses that WebRTC has collected and relays them to you so that you can associate them with your public IP address."
    },
    howFixWebRTC: {
      title: "How to fix WebRTC leak"
    }
  },
  evercookieTest: {
    main: {
      title: "Evercookie test",
      subtitle:
        "Evercookie is created to protect the data which supposed to be persistent by storing these data in several locations simultaneously. In case of any of the data was lost (if you cleared cookies, for example), it can be recovered and then reset and reused"
    },
    testResult: "Evercookie test result",
    infoCreate:
      "Click to create an evercookie. Your anonymity will not be violated",
    buttonCreate: "Create an Evercookies",
    uidNotSet: "CURRENTLY NOT SET",
    cookieFound: "COOKIE FOUND UID",
    infoRediscover:
      "Please try to delete this 'uid' cookie in any possible way, then click the button below",
    buttonRediscover: "Rediscover cookies",
    table: {
      name: "Name",
      value: "Value"
    }
  },
  browserInformation: {
    main: {
      title: "Browser information",
      subtitle:
        "An online browser information finder is a tool that will help you find information about your web browser and determine your IP address"
    },
    youreUsing: "You’re using",
    yourIP: "Your IP:",
    table: {
      os: "Operating system",
      ip: "IP",
      cms: "CMS",
      language: "Language",
      userAgent: "UserAgent",
      application: "Application version"
    }
  },
  faq: {
    heading: "General FAQs",
    description:
      "Everything you need to know about the product and how it works. Can’t find an answer? Please <a href='/feedback/'>contact us</a>"
  },
  proxyFilters: {
    rating: "By rating",
    reviews: "By reviews",
    costs: "By price"
  },
  buttons: {
    viewMore: "View more"
  },
  noData: {
    select: {
      default: "No data"
    }
  },
  layouts: {
    auth: {
      registration: {
        title: "Get started create proxy reviews with TrustyTech",
        subtitle: "Sign up on our service in seconds!"
      },
      login: {
        title: "Welcome back to TrustyTech",
        subtitle: "Please login to your account "
      },
      feedback: {
        title: "We are always ready to answer your questions",
        subtitle: "and listen to your suggestions to improve our service"
      },
      default: {
        title: "Welcome back to TrustyTech",
        subtitle: "Please login to your account"
      }
    }
  },
  continents: {
    ASIA: "Asia",
    AFRICA: "Africa",
    NORTH_AMERICA: "North America",
    SOUTH_AMERICA: "South America",
    ANTARCTICA: "Antarctica",
    EUROPE: "Europe",
    AUSTRALIA: "Australia"
  },
  howToBuy: {
    title: "How to buy ",
    proxy: "Proxy",
    subtitle:
      "How to buy a diverse range of proxy types: datacenter IPv4 & IPv6, ISP, residential, and 4G/5G rotating mobile:",
    proxySubtitle:
      "To buy high-quality IPv4 proxies located in a huge number of networks and subnets with a choice of suitable locations",
    listTitle:
      "Services are provided according to the characteristics in the catalog"
  },
  languages: {
    title: "Language",
    en: "English",
    ru: "Russian",
    ua: "Ukrainian"
  }
};
