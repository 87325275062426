export default {
  header: {
    logo: {
      imgAlt: "Rating Proxy - Незалежний рейтинг проксі-серверів",
      linkTitle: "Перейти на головну сторінку"
    },
    burger: {
      ariaOpen: "Відкрити меню",
      ariaClose: "Закрити меню"
    },
    cabinet: {
      enter: "Вхід",
      leave: "Вийти",
      cabinet: "Вхід"
    },

    bottom: {
      geo: {
        title: "Проксі за гео"
      },
      for: {
        title: "Прокси для"
      },
      vpn: {
        title: "ВПН",
        countries: {
          title: "ЗА КРАЇНАМИ"
        },
        devices: {
          title: "ДЛЯ ПРИСТРОЇВ"
        },
        uses: {
          title: "ДЛЯ ВИКОРИСТАННЯ"
        }
      },
      type: {
        title: "ТИП ПРОКСІ",
        ipv4: "Проксі IPv4",
        ipv6: "Проксі IPv6",
        mobile: "Мобільні проксі",
        free: "Безкоштовні проксі"
      },
      accounts: {
        title: "Аккаунти",
        countries: {
          title: "ЗА КРАЇНАМИ"
        },
        devices: {
          title: "ДЛЯ ПРИСТРОЇВ"
        },
        uses: {
          title: "ДЛЯ ВИКОРИСТАННЯ"
        }
      }
    }
  },
  footer: {
    logo: {
      imgAlt: "Rating Proxy - Незалежний рейтинг проксі-серверів",
      linkTitle: "Перейти на головну сторінку",
      description:
        "Вибираючи, де купити персональні проксі для парсингу, арбітражу чи обходу блокувань"
    },
    form: {
      title: "Передплата",
      inputPlaceholder: "Введіть пошту",
      btn: "Надіслати",
      langTitle: "Мова:"
    }
  },
  table: {
    cell: {
      period: {
        eternal: "∞ Безстроково"
      }
    }
  },
  pagination: {
    table: {
      from: "з",
      rowsCount: "рядків/ на сторінці"
    }
  },
  metaPage: {
    title: "TrustyTech - Незалежний рейтинг проксі-серверів",
    description:
      "Більше 100 проксі сервісів, які забезпечать стабільну роботу в соціальних мережах, SEO та маркетингу, ставках та онлайн іграх."
  },
  recentReviews: { title: "Останні відгуки", all: "Всі відгуки" },
  mainPage: {
    proxy: {
      titles: {
        IPv4: "Рейтинг постачальників проксі IPv4",
        MobileProxy: "Рейтинг постачальників мобільних проксі",
        ResidentialProxy: "Рейтинг постачальників резидентних проксі",
        ISP: "Рейтинг постачальників проксі ISP",
        IPv6: "Рейтинг постачальників проксі IPv6",
        FreeProxy: "Рейтинг постачальників безкоштовних проксі",
        SharedProxy: "Рейтинг постачальників спільних проксі",
        RemoteServer: "Рейтинг постачальників віддалених серверів"
      },
      description:
        "Більше 1368 проксі-серверів у нашому рейтингу. Знайдіть компанії, яким ви можете довіряти"
    },
    userChoice: {
      title: "Вибір користувачів",
      columns: {
        name: "Назва",
        price: "Ціна",
        offers: "Пропозиції",
        site: "Сайт",
        new: "Новий"
      }
    },

    determineIp: {
      yourIp: "Ваш IP",
      notCountry: "Країна не визначена",
      changeLocat: "Змінити розташування",
      changeBtn: "Змінити"
    },
    title: "Незалежний рейтинг проксі-серверів",
    subtitle: {
      startLine: "Проксі сервіси для",
      colorLine: "будь-якої",
      endLine: "вашої задачі"
    },
    highlightWord: "рейтинг",
    description:
      "Більше 100 проксі сервісів, які забезпечать стабільну роботу в соціальних мережах, SEO та маркетингу, ставках та онлайн іграх."
  },
  blogPage: {
    breadcrumbs: [
      {
        title: "Блог"
      }
    ],
    title: "Блог",
    tabs: [
      {
        name: "all",
        title: "Всі"
      },
      {
        name: "articles",
        title: "Статті"
      },
      {
        name: "news",
        title: "Новини"
      },
      {
        name: "informational",
        title: "Информаційні"
      },
      {
        name: "browser",
        title: "Браузер"
      },
      {
        name: "proxyIPv4",
        title: "Проксі IPv4"
      },
      {
        name: "social media",
        title: "Соціальні медіа"
      },
      {
        name: "integrations",
        title: "Інтеграція"
      },
      {
        name: "proxies",
        title: "Проксі"
      }
    ],
    tabsTitle: "Категорії:",
    emptyArticles: "Статей немає",
    emptyNews: "Новин немає",
    item: {
      categoryTitle: "Категорія",
      categories: {
        article: "Стаття",
        news: "Новина"
      },
      commentsTitle: "Коментарі",
      readMore: "Читати далі",
      popular: "Популярна стаття"
    },
    latest: "Останні",
    search: "Пошук"
  },
  blogInnerPage: {
    breadcrumbs: [
      {
        route: "blog/?category=all",
        title: "Блог"
      }
    ],
    articleBody: {
      commentsTitle: "Коментарі",
      viewsTitle: "переглядів",
      shareTitle: "Чи була корисна стаття? Розкажіть про неї своїм знайомим",
      shareBtns: {
        vk: "Поділитися у Вконтакті",
        twitter: "Поділитися у Твіттері",
        telegram: "Поділитися у Телеграмі",
        linkedin: "Поділитися у Лінкедині",
        facebook: "Поділитися у Фейсбуці"
      },
      inThisArticle: "В цій статті:",
      relatedTitle: "Схожі статті",
      prev: "Попередня",
      next: "Наступна"
    },
    comments: {
      noData: "Немає коментарів"
    },
    sidebar: {
      latest: {
        title: "ОСТАННІ ДОДАНІ СТАТТІ"
      },
      popular: {
        title: "Популярні статті"
      },
      more: "Читати далі"
    }
  },
  addsitePage: {
    title: "Додати сайт",
    breadcrumbs: [
      {
        title: "Додати сайт"
      }
    ],
    tabs: [
      {
        name: "proxy",
        title: "Проксі сервіс"
      }
    ],
    tabsTitle: "Категорії:"
  },
  proxyPage: {
    proxy: {
      titles: {
        IPv4: "Оберіть підключення до проксі сервісу IPv4",
        MobileProxy: "Оберіть підключення до мобільного проксі сервісу",
        ResidentialProxy: "Оберіть підключення до резидентного проксі сервісу",
        ISP: "Оберіть підключення до проксі сервісу ISP",
        IPv6: "Оберіть підключення до проксі сервісу IPv6",
        FreeProxy: "Оберіть підключення до безкоштовного проксі сервісу",
        SharedProxy: "Оберіть підключення до спільного проксі сервісу",
        RemoteServer: "Оберіть підключення до віддаленого серверу"
      },
      description:
        "Більше 1368 проксі-серверів у нашому рейтингу. Знайдіть компанії, яким ви можете довіряти"
    },
    sidebar: {
      promocodes: {
        title: "Промокоди"
      },
      left: {
        countries: {
          title: "Проксі за ГЕО"
        },
        goals: {
          title: "ЦІЛІ ВИКОРИСТАННЯ",
          items: {
            socialNetwork: "Для соц. мереж",
            onlineGames: "Онлайн ігри",
            searchEngineParsing: "Парсинг пошукових систем",
            other: "Інше"
          }
        }
      },
      right: {
        comparison: {
          title: "ПОРІВНЯННЯ",
          compareAction: "Порівняти",
          clearAction: "Очистити"
        },
        sellers: {
          title: "Топ продавців",
          promocode: "Промокод для вас",
          goBtn: "Перейти"
        },
        reviews: {
          title: "ОСТАННІ ВІДГУКИ"
        }
      }
    },
    specialOffer: {
      title: "Спеціальні пропозиції",
      description:
        "Більше 100 проксі сервісів, які забезпечать стабільну роботу в соціальних мережах, SEO та маркетингу, ставках та онлайн іграх."
    },
    content: {
      top: {
        proxy: "Запит на проксі",
        title: {
          main: "Рейтинг проксі сервісів",
          divider: ""
        }
      },
      filter: {
        title: "Проксі тип:",
        rating: "Рейтинг",
        review: "Відгуки",
        cost: "Вартість"
      },
      sort: {
        title: "Сортування за"
      },
      table: {
        title: {
          services: "сервісів"
        },
        index: {
          top: "ТОП"
        },
        name: {
          name: "Назва",
          domainYear: "Реєстрація домену",
          domainYearEnding: "р.",
          countriesQuantity: "К-ть країн у продажу",
          promocode: "Промокод"
        },
        rating: {
          name: "Рейтинг",
          notRated: "Без рейтингу",
          halfStar: "Половина зірки",
          oneStar: "Одна зірка",
          oneHalfStars: "Одна з половиною зірка",
          twoStars: "Дві зірки",
          twoHalfStars: "Дві з половиною зірки",
          threeStars: "Три зірки",
          threeHalfStars: "Три з половиною зірки",
          fourStars: "Чотири зірки",
          fourHalfStars: "Чотири з половиною зірки",
          fiveStars: "П'ять зірок",
          stars: "зірки"
        },
        description: {
          title: "Опис",
          noDescription: "Опису немае"
        },
        advantages: "Переваги",
        type: {
          title: "Тип",
          noContent: "Немає типів"
        },
        freeTest: {
          title: "Безкоштовний тест",
          yes: "Так",
          no: "Ні"
        },
        characteristics: {
          title: "Характеристики",
          autorization: {
            title: "Авторизація",
            login: "Логін+пароль",
            ip: "За основним IP",
            noAuth: "Без авторизації"
          }
        },
        geo: {
          title: "ГЕО",
          more: "ще",
          noContent: "Немає країн",
          modal: {
            title: "Всі країни",
            search: "Пошук",
            subtitles: {
              other: "Інші країни"
            }
          }
        },
        minPrice: {
          title: "Мін. ціна"
        },
        minRent: {
          title: "Мін. оренда"
        },
        pp: {
          title: "ПП",
          noData: "-"
        },
        api: {
          title: "API",
          // newTitle: "Бесплатный тест",
          newTitle: "Безкоштовний тест",
          yes: "Так",
          no: "Ні"
        },
        site: {
          title: "Сайт",
          btn: "Перейти",
          readOverview: "Читати огляд"
        },
        compare: {
          title: "Порівняти",
          ariaLabel: "Додати до порівняння"
        },
        top: "Топ сервіс",
        noDataComponent: "Немає даних"
      },
      modal: {
        title:
          "Виберіть необхідні дані і ми розішлемо запит усім відповідним за категоріями компаніям нашого каталогу",
        quantity: {
          placeholder: "Кількість"
        },
        btn: "Надіслати"
      }
    }
  },
  proxySitePage: {
    info: {
      tabs: {
        info: "Інформація",
        social: "Соц. мережі",
        contacts: "Контакти"
      },
      base: {
        domain: "Домен",
        rating: "Рейтинг",
        reviews: "Відгуки",
        reviewCount: "відгуків",
        reviewCountTwo: "відгуки",
        reviewCountOne: "відгук"
      },
      informations: {
        title: "Послуги",
        minRent: "Мін. оренда",
        minPrice: "Мін. ціна",
        pullIP: "Kількість IP",
        million: "млн",
        thousand: "тис",
        minGB: "Мін. ГБ",
        minIP: "Мін. IP",
        individual: "Індивідуальні"
      },
      socialMedia: {
        title: "Соціальні мережі"
      },
      contacts: {
        title: "Контакти"
      }
    },
    about: {
      title: "Про сервіс",
      visit: "Перейти на сайт",
      readReview: "Читати огляд",
      info: {
        title: "Інформація",
        countries: "Країни",
        allLocations: "Всі локації",
        speed: "Швидкість",
        ping: "Пінг",
        auth: "Авторизація",
        api: "API",
        reviewTitle: "Останні відгуки",
        allReviews: "Всі відгуки",
        pingTooltip: "Приблизні показники пінгу, які гарантує сервіс",
        speedTooltip: "Приблизні показники швидкості, які гарантує сервіс",
        affiliate: "Партнерська програма",
        refund: "Повернення коштів",
        freeTest: "Безкоштовний тест"
      },
      reviews: {
        title: "Залишити відгук",
        btn: "Додати відгук",
        basedOn: "на основі відгуків за останні шість місяців"
      },
      tabs: {
        info: "Інформація",
        reviews: "Відгуки",
        rating: "Графік рейтингу",
        promocodes: "Промокоди"
      },
      rating: {
        title: "Графік рейтингу",
        color: "Рейтинг"
      },
      promocodes: {
        title: "Промокоди",
        subscribe: "Підписатися на промокоди"
      }
    },
    content: {
      top: {
        btn: "Перейти до сайту"
      },
      tabs: [
        {
          title: "Характеристики",
          name: "characteristics"
        },
        {
          title: "Відгуки",
          name: "reviews"
        },
        {
          title: "Графік рейтингу",
          name: "rating"
        },
        {
          title: "Промокоди",
          name: "promocodes"
        },
        {
          title: "Контакти",
          name: "contacts"
        }
      ],
      contacts: {
        noData: "Немає контактів"
      },
      chacracteristics: {
        market: {
          title: "На ринку"
        },
        rating: {
          title: "Рейтинг"
        },
        countries: {
          title: "Країни"
        },
        freeTest: {
          title: "Безкоштовний тест"
        },
        individual: {
          title: "Індивідуальні"
        },
        change: {
          title: "Можливість заміни"
        },
        type: {
          title: "Тип"
        },
        minCost: {
          title: "Мінімальна вартість"
        },
        minRent: {
          title: "Мінімальний термін оренди"
        },
        reviews: {
          title: "Відгуки"
        },
        goals: {
          title: "Цілі використання"
        },
        auth: {
          title: "Авторизація",
          login: "Логін+пароль",
          ip: "За основним IP",
          noAuth: "Без авторизації"
        },
        return: {
          title: "Повернення коштів"
        },
        partnership: {
          title: "Партнерська програма"
        },
        partnershipPercent: {
          title: "Відсоток за партнерською програмою"
        },
        services: {
          title: "Перевірте найкращі альтернативні сервіси"
        },
        description: {
          title: "Опис",
          noDescription: "Опису немае"
        },
        alternative: {
          title: "Альтернативні сервіси"
        }
      },
      rating: {
        labels: [
          "січ",
          "лют",
          "бер",
          "квіт",
          "трв",
          "чер",
          "лип",
          "сер",
          "вер",
          "жов",
          "лис",
          "грд"
        ],
        label: "Рейтинг",
        filters: {
          month: "Останній місяць",
          year: "Останній рік",
          quarter: "Останній квартал",
          general: "Загальний"
        }
      },
      reviews: {
        top: {
          title: "Сортувати за",
          sortList: [
            {
              label: "Корисності",
              param: "useful"
            },
            {
              label: "Датою",
              param: "creationDate"
            },
            {
              label: "Оцінкою",
              param: "rating"
            }
          ]
        },
        noData: "Відгуків немає"
      },
      complaints: {
        top: {
          title: "Сортувати за",
          sortList: [
            {
              label: "датою",
              param: "creationDate"
            }
          ]
        },
        noData: "Скарг немає"
      },
      promocodes: {
        title: "Промокод",
        btn: "Показати промокод",
        noData: {
          title: "Послуга активних промокодів {{value}} ще не додана",
          description:
            "Якщо ви є власником сервісу, будь ласка, авторизуйтесь та створіть промокод",
          titleAlternative: "Промокоди альтернативних сервісів"
        },
        noDescription: "Опису немає"
      }
    },
    notFound: "Сайт не знайдено"
  },
  comparisonPage: {
    title: "Таблиця порівняння",
    table: {
      name: "Ім'я",
      rating: "Рейтинг",
      link: "Посилання на сайт",
      freeTest: "Безкоштовний тест",
      individual: "Індивідуальні",
      replacement: "Можливість заміни",
      countries: "Країни",
      type: "Тип",
      purposes: "Цілі використання",
      minCost: "Мінімальна вартість",
      minRent: "Мінімальний термін оренди",
      auth: "Авторизація",
      refunds: "Повернення коштів",
      partnership: "Партнерська програма",
      reviews: "Відгуки",
      remove: "Видалити"
    },
    noData: "Порівняння більше не існує!",
    returnText: "Повернутися на головну"
  },
  authPages: {
    navigate: {
      toLogin: {
        text: "Вже є аккаунт?",
        btn: "Увійти"
      },
      toRegister: {
        text: "Немає аккаунта?",
        btn: "Зареєструватись"
      }
    },
    agree: {
      with: "Я погоджуюсь з",
      offer: "Офертою",
      privacy: "Політикою конфіденційності",
      and: "та"
    },
    signIn: {
      title: "Авторизація",
      btn: "Увійти",
      remember: "Запам'ятати мене",
      forgot: "Забули пароль"
    },
    resetPassword: {
      title: "Скидання пароля",
      subtitle: "Ми надішлемо вам інструкції зі скидання пароля.",
      success: "Посилання для скидання пароля надіслано на вашу пошту",
      error:
        "Користувача з такою адресою електронної пошти не знайдено. Спробуйте ввести іншу адресу електронної пошти або перевірте правильність введеної адреси електронної пошти.",
      btn: "Надіслати посилання"
    },
    resetPasswordPage: {
      title: "Встановлення нового пароля",
      subtitle: "Введіть новий пароль",
      btn: "Встановити новий пароль",
      error: "Посилання для скидання пароля недійсне або закінчилося"
    },
    auth: {
      title: "Авторизація",
      btn: "Увійти"
    },
    register: {
      title: "Реєстрація",
      btn: "Зареєструватись"
    },
    registerSuccess: {
      title: "Дякуємо за реєстрацію!",
      text: "Ми відправили вам лист з підтвердженням.",
      spam: "Якщо ви не отримали листа, перевірте папку «Спам».",
      btn: "Перейти в кабінет"
    },
    recovery: {
      title: "Відновлення акаунту",
      btn: "Відновити"
    },
    feedbackPage: {
      title: "Зворотній зв'язок",
      subtitle:
        "Використовуйте цю форму, якщо ви хочете поставити нам запитання або повідомити про помилку. Будь ласка, зробіть своє повідомлення якомога докладнішим, тоді ми зможемо вирішити проблему набагато швидше."
    },
    passRecovery: "Нагадати пароль"
  },
  cabinetPages: {
    title: "Особистий кабінет",
    tabs: {
      proxy: "Проксі сервіси",
      complaints: "Скарги",
      promocodes: "Промокоди",
      notifications: "Сповіщення",
      exit: "Вихід"
    },
    proxy: {
      list: {
        new: "Додати новий сервіс",
        edit: "Редагувати",
        delete: "Видалити",
        cancel: "Скасувати",
        modalTitle: "Ви впевнені, що хочете видалити сервіс?",
        noData: "Немає сервісів"
      },
      create: {
        title: "Додати новий проксі-сервіс"
      },
      edit: {
        title: "Редагування проксі сервісу"
      }
    },
    promocodes: {
      list: {
        new: "Додати новий промокод",
        edit: "Редагувати",
        delete: "Видалити",
        cancel: "Скасувати",
        modalTitle: "Ви впевнені, що хочете видалити промокод?",
        noData: "Немає промокодів"
      },
      create: {
        title: "Додати промокод"
      },
      edit: {
        title: "Редагувати промокод"
      }
    },
    breadcrumbs: {
      "cabinet-proxy": [
        {
          title: "Особистий кабінет"
        }
      ],
      "cabinet-proxy-create": [
        {
          title: "Особистий кабінет",
          route: "cabinet/proxy/"
        },
        {
          title: "Створення проксі сервісу"
        }
      ],
      "cabinet-proxy-edit": [
        {
          title: "Особистий кабінет",
          route: "cabinet/proxy/"
        },
        {
          title: "Редагування проксі сервісу"
        }
      ],
      "cabinet-promocodes": [
        {
          title: "Особистий кабінет",
          route: "cabinet/proxy/"
        },
        {
          title: "Промокоди"
        }
      ],
      "cabinet-promocodes-create": [
        {
          title: "Особистий кабінет",
          route: "cabinet/proxy/"
        },
        {
          title: "Промокоди",
          route: "cabinet/promocodes/"
        },
        {
          title: "Створення промокоду"
        }
      ],
      "cabinet-promocodes-edit": [
        {
          title: "Особистий кабінет",
          route: "cabinet/proxy/"
        },
        {
          title: "Промокоди",
          route: "cabinet/promocodes/"
        },
        {
          title: "Редагування промокоду"
        }
      ]
    }
  },
  feedbackPage: {
    title: "Залишити відгук",
    text: [
      "Ми завжди готові відповісти на питання, що Вас цікавлять, а також вислухати Ваші пропозиції щодо покращення нашого сервісу.",
      "Використовуйте цю форму, якщо хочете поставити нам запитання, або повідомити про помилку. Будь ласка, робіть Ваше повідомлення якомога розгорнутим, тоді ми зможемо вирішити проблему набагато швидше."
    ],
    form: {
      btn: "Надіслати"
    },
    success: {
      title: "Дякуємо за ваш відгук!",
      subtitle: "Ваш коментар прийнято. Найближчим часом ми його опублікуємо!",
      btn: "Повернутися на головну"
    }
  },
  navigation: {
    promotionals: "Промокоди",
    addsite: "Додати сайт",
    feedback: "Зворотній зв'язок",
    blog: "Блог",
    logIn: "Вхід",
    selectService: "Оберіть сервіс",
    freeProxy: {
      title: "Безкоштовні проксі",
      description:
        "Використовуйте список проксі абсолютно безкоштовно без авторизації"
    },
    browserFingerprint: {
      title: "Анонімна ідентифікація браузерів",
      description: "Перегляд властивостей анонімної ідентифікації браузерів"
    },
    portScanner: {
      title: "Сканер портів",
      description: "Тестуйте списки проксі, щоб уникнути можливих помилок"
    },
    tracingIP: {
      title: "Трасування IP",
      description:
        "Знайти проблеми з підключенням і отримати більше корисних даних про це"
    },
    dndLeakTest: {
      title: "Тест витоку DNS",
      description: "Перевірте свою конфіденційність і надійність"
    },
    ipBlacklist: {
      title: "Перевірка чорного списку IP",
      description:
        "Використовуйте, щоб дізнатися, чи є ваша IP у чорному списку"
    },
    myAnonymity: {
      title: "Моя анонімність",
      description: "Перевірити ступінь анонімності в мережі"
    },
    webRTC: {
      title: "Тест витоку WebRTC",
      description: "Захистіть свої особисті дані, усунувши витоки WebRTC"
    },
    httpHeaders: {
      title: "Перевірка заголовків HTTP",
      description: "Забезпечує аналіз заголовків кожної сторінки сайту"
    },
    evercookieTest: {
      title: "Тест Evercookie",
      description:
        "Тестуйте файли cookie та інші засоби відстеження, які використовує ваш веб-сайт"
    },
    browserInformation: {
      title: "Інформація про браузер",
      description: "Дізнайтеся всю інформацію про свій браузер"
    },
    ipv4: {
      title: "IPv4 проксі",
      description: "Виділена якість проксі за доступною ціною"
    },
    ipv6: {
      title: "IPv6 проксі",
      description: "Пакет індивідуальних IP-адрес з необмеженим терміном дії"
    },
    mobileProxy: {
      title: "Мобільні LTE проксі",
      description:
        "Скористайтеся перевагами високошвидкісних обертових мобільних проксі-серверів 4G із динамічною IP-адресою"
    },
    residentialProxy: {
      title: "Резидентські проксі",
      description: "Найдоступніші резидентські проксі"
    },
    sharedProxy: {
      title: "Shared проксі",
      description:
        "Використовуйте стабільні, швидкі та люті спільні IP-адреси по всьому світу"
    },
    isp: {
      title: "ISP проксі"
    },
    remoteServer: {
      title: "Віддалений сервер"
    },
    topLocations: "Найкращі локації",
    europe: "Європа",
    asia: "Азія",
    northAmerica: "Північна Америка",
    southAmerica: "Південна Америка",
    australiaOceania: "Австралія та Океанія",
    africa: "Африка",
    tools: "Інструменти",
    resources: "Ресурси",
    interestingRead: "Цікаво почитати",
    proxies: "Проксі",
    locations: "Локації",
    services: "Сервіси"
  },
  breadcrumbs: {
    main: "Головна"
  },
  proxy: {
    name: {
      label: "Сайт",
      placeholder: "Назва сайту"
    },
    link: {
      label: "Посилання на сервіс"
    },
    referralLink: {
      label: "Реферальне посилання"
    },
    proxyType: {
      title: "Тип"
    },
    countries: {
      title: "Країни"
    },
    goals: {
      title: "Цілі використання"
    },
    minRent: {
      label: "Мінімальний термін оренди"
    },
    minPrice: {
      label: "Мінімальна ціна"
    },
    auth: {
      title: "Авторизація",
      noAuth: "Без авторизації",
      loginAuth: "Логін + пароль",
      ipAuth: "За основним IP"
    },
    benefits: {
      title: "Переваги",
      moneyBack: "Повернення грошей 30 днів",
      support: "Підтримка 24/7",
      anonymity: "Анонімність",
      allPlatforms: "Усі платформи"
    },
    freeTest: {
      label: "Безкоштовний тест"
    },
    refunds: {
      label: "Повернення коштів"
    },
    replacementPossibility: {
      label: "Заміна IP"
    },
    api: {
      label: "API"
    },
    affiliateProgram: {
      label: "Партнерська програма"
    },
    individual: {
      label: "Індивідуальні",
      tooltip:
        "Установіть прапорець, якщо ваші IP-адреси надаються для індивідуального використання"
    },
    promocode: {
      label: "Промокод"
    },
    description: {
      label: "Опис"
    },
    socialNetworks: {
      placeholder: "Введіть "
    },
    affiliatePercent: {
      label: "Відсоток за партнерською програмою"
    },
    contacts: {
      title: "Контактні дані",
      description: "Буде відображено на сторінці вашого сервісу"
    },
    logotype: {
      title: "Логотип",
      btn: {
        delLogo: "Видалити логотип"
      },
      modal: {
        btn: {
          reset: "Скинути",
          save: "Зберегти"
        }
      }
    },
    checkAll: {
      label: "Обрати все"
    },
    btn: {
      add: "Додати сервіс",
      edit: "Редагувати сервіс"
    }
  },
  promocodes: {
    showCode: "Показати промокод",
    valid: "Дійсний до",
    subscribe: "Підписатися на нові промокоди",
    copy: "Скопіювати",
    site: {
      label: "Сайт",
      placeholder: "Оберіть сайт",
      noData: "У вас ще немає сайтів"
    },
    promocode: {
      label: "Промокод",
      placeholder: "Введіть промокод"
    },
    description: {
      label: "Опис",
      placeholder: "Опис промокоду"
    },
    eternal: {
      label: "Нескінченний",
      name: "Нескінченний"
    },
    isActive: {
      label: "Активний"
    },
    date: {
      label: "Дата дії"
    },
    btn: {
      add: "Додати промокод",
      edit: "Редагувати промокод"
    }
  },
  notifications: {
    serverError: "Серверна помилка! Спробуйте пізніше!",
    apiError: "Щось пішло не так!",
    invalidToken: "Невірний токен!",
    incorrectData: "Некоректні дані!",
    copy: "Скопійовано!",
    copied: "Скопійовано",
    vote: "Дякуємо за ваш голос!",
    comment: "Ваш коментар прийнято. Найближчим часом ми його опублікуємо!",
    feedback: "Дякую за ваш відгук!",
    proxyQuery: "Запит на проксі був успішно надісланий!",
    passwordRecovery: "Пароль був успішно змінений!",
    authError: {
      400: "Невірний токен!",
      401: "Невірний логін або пароль!",
      540: "Такий користувач уже зареєстровано!",
      543: "Помилка введення капчі, будь ласка, спробуйте знову!",
      560: "Користувач не підтверджено! Для підтвердження перейдіть за посиланням у листі у вашому Email!",
      561: "Користувач заблоковано!",
      545: "Неіснуюча пошта! Будь ласка, введіть існуючу пошту!"
    },
    comparison: {
      added: "Сайт доданий до порівняння!",
      removed: "Сайт видалено з порівняння!"
    },
    emailSent:
      "Вам було надіслано на пошту повідомлення з посиланням на відновлення пароля! Будь ласка, перевірте пошту.",
    site: {
      creating: "Створення сайту",
      created: "Ваш сайт був успішно створений!",
      removed: "Ваш сайт був успішно вилучений!",
      edited: "Ваш сайт було успішно оновлено!",
      exist: "Сайт з таким посиланням вже існує!"
    },
    promocode: {
      created: "Ваш промокод було успішно створено!",
      removed: "Ваш промокод успішно видалено!",
      edited: "Ваш промокод успішно оновлено!",
      duplicate: "Промокод із таким значенням вже існує!"
    },
    image: {
      uploading: "Завантаження зображення",
      uploaded: "Зображення було успішно завантажено!"
    },
    file: {
      badFile: "Щось не так з файлом!",
      badFileType: "Тип файлу не відповідає .jpeg або .png",
      largeSize: "Розмір файлу більше 2 МБ."
    },
    modals: {
      review: "Дякую за ваш відгук! Ваш відгук був відправлений на модерацію!",
      reviewEdit: "Ваш відгук відредаговано та було відправлено на модерацію!",
      reviewDelete: "Відгук було успішно видалено!",
      complaint:
        "Дякуємо за вашу скаргу! Ваша скарга була відправлена на модерацію!",
      comment: "Ваш коментар прийнято. Найближчим часом ми його опублікуємо!",
      reviewAlreadySend: "З вашого ІП/Email вже залишили відгук цьому сервісу!",
      tooOften: "Ви занадто часто надсилаєте форму",
      formBlocked: "Відправка форми заблокована"
    },
    blogInner: {
      notFoundBlogPost: "Пост блогу не знайдено!"
    }
  },
  dashboard: {
    navBar: {
      profile: "профіль",
      services: "сервіси",
      reviews: "відгуки",
      promocodes: "промокоди",
      hello: "Привіт",
      logout: "Вийти",
      addSite: "Додати сайт"
    },
    profile: {
      title: "Профіль",
      blockEmail: {
        title: "E-mail адреса",
        subtitle: "Керуйте вашою електронною адресою",
        change: "Змінити e-mail"
      },
      blockPassword: {
        title: "Пароль",
        subtitle: "Керуйте вашим паролем",
        help: "Мінімум 6 символів"
      },
      confirmModal: {
        title: "Підтвердження змін",
        subtitle:
          "Будь ласка, введіть спеціальний код з вашої електронної пошти",
        timer: "Код буде активним",
        resend: "Відправити код повторно",
        invalidToken: "Невірний токен",
        invalidPassword: "Пароль не відповідає поточному",
        invalidEmail: "Будь ласка введіть існуючий email",
        emailExist: "Цей email вже використовується",
        successEmail: "Email успішно змінено",
        successPassword: "Пароль успішно змінено",
        successSend: "На вашу пошту відправлено лист з кодом підтвердження"
      }
    },
    services: {
      table: {
        title: "Сервіси",
        add: "Додати сервіс",
        sort: "Сортувати за",
        filter: "Фільтрувати за",
        all: "Всі",
        columns: {
          name: "Назва",
          date: "Дата",
          status: "Статус",
          views: "Перегляди",
          reviews: "Відгуки",
          conversions: "Конверсії",
          actions: {
            title: "Дії",
            edit: "Редагувати",
            view: "Переглянути сторінку",
            delete: "Видалити"
          }
        }
      },
      noData: {
        title: "У вас ще немає відкритих позицій",
        subtitle: "Додайте проксі і він з'явиться у цій таблиці."
      },
      modal: {
        delete: {
          title: "Видалити сервіс",
          subtitle:
            "Ви впевнені, що хочете видалити цей сервіс з вашого рейтингу?"
        }
      },
      addEdit: {
        titleAdd: "Додати сервіс",
        titleEdit: "Редагувати сервіс",
        add: "Додати сервіс",
        edit: "Редагувати сервіс",
        blocks: {
          logo: {
            title: "Логотип компанії",
            subtitle: "Завантажте файл або надайте посилання нижче",
            check: "Перевірка",
            download: "Завантажте файл і підтвердіть",
            instruction: {
              title: "Інструкція",
              variantMeta: "Додайте метатег на ваш сайт",
              variantFile: "або додайте файл в корінь сайту",
              variantFileExample: "Шлях до файлу повинен бути таким:"
            },
            checkError:
              "Ми не можемо перевірити ваш сайт. Переконайтеся, що ви дотримуєтеся інструкцій щодо перевірки та спробуйте ще раз.",
            checkSuccess: "Сайт успішно перевірено!",
            downloadFileError: "Помилка завантаження файлу"
          },
          characteristics: {
            title: "Характеристики",
            subtitle: "Вкажіть середнє значення послуг, які Ви пропонуєте",
            speed: "Швидкість",
            ping: "Пінг",
            speedPlaceholder: "МБ/с",
            pingPlaceholder: "мс",
            from: "Від",
            to: "До"
          },
          description: {
            title: "Опис",
            subtitle: "Додайте невеликий опис вашого сервісу"
          },
          type: {
            title: "Тип",
            subtitle: "Виберіть тип проксі та додайте опис характеристик",
            advantages: {
              title: "Переваги",
              placeholder: "Анонімність, тощо.",
              add: "Додати перевагу"
            },
            countries: {
              active: "Активні країни",
              inactive: "Неактивні країни",
              description:
                "Ці країни не входять до нашого каталогу, ви можете вибрати їх. Модератор сайту розгляне можливість створення необхідної країни"
            }
          },
          auth: {
            title: "Авторизація",
            subtitle: "Виберіть тип авторизації"
          },
          benefits: {
            title: "Переваги",
            subtitle: "Виберіть переваги проксі-сервісу"
          },
          features: {
            title: "Функціонал",
            subtitle: "Виберіть функціонал сервісу",
            descriptions: {
              refundDays:
                "Вкажіть період, протягом якого користувач може запросити повернення коштів."
            }
          },
          promocode: {
            title: "Промокод",
            subtitle:
              "Промокод дозволяє відрізнити сервіс від інших та надати знижку користувачу",

            show: "Як це виглядає?"
          },
          contacts: {
            title: "Контактні дані",
            subtitle: "Додайте свої контакти та соціальні мережі"
          }
        }
      }
    },
    promocodes: {
      table: {
        title: "Промокоди",
        add: "Додати промокод",
        sort: "Сортувати за",

        noDataFilter: "Немає даних",
        columns: {
          name: "Назва",
          promocode: "Промокод",
          status: "Статус",
          date: "Дата",
          active: "Активний",
          actions: {
            title: "Дії",
            edit: "Редагувати",
            delete: "Видалити"
          }
        }
      },
      activated: {
        success: "Статус промокоду успішно змінено!",
        notFound: "Промокод не знайдено!",
        startsLater: "Дата початку промокоду ще не настала!",
        isExpired: "Дата закінчення промокоду вже минула!"
      },
      noData: {
        title: "У вас ще немає промокодів",
        subtitle: "Додайте промокод і він з'явиться у цій таблиці."
      },
      modal: {
        delete: {
          title: "Видалити промокод",
          subtitle: "Ви впевнені, що хочете видалити цей промокод?"
        }
      },
      addEdit: {
        titleAdd: "Додати промокод",
        titleEdit: "Редагувати промокод",
        add: "Додати промокод",
        edit: "Редагувати промокод",
        blocks: {
          site: {
            title: "Сайт",
            subtitle: "Додайте ваш сайт, наприклад, www.sitename.com"
          },
          promocode: {
            title: "Промокод",
            subtitle:
              "Промокод розміщується на добровільній основі з метою відрізнення сервісу від інших"
          },
          description: {
            title: "Опис",
            subtitle:
              "Виберіть мову введення та додайте короткий опис промокоду"
          },
          period: {
            title: "Термін дії",
            subtitle: "Встановіть термін дії промокоду",
            date: "Дата"
          },
          active: {
            description:
              "Будь ласка, щоб уникнути складнощів застосування промокоду користувачами, активуйте промокод тільки у разі коли він активний."
          }
        }
      }
    },
    reviews: {
      title: "Мої відгуки",
      add: "Додати відгук",
      appeal: {
        btn: "Надіслати апеляцію",
        modal: {
          title: "Коментар до апеляції"
        }
      },
      noData: {
        title: "У вас ще немає жодного відгуку",
        subtitle: "Додайте відгук і він з'явиться у цій таблиці."
      },
      sortList: [
        {
          label: "Датою",
          value: "CREATION_DATE"
        },
        {
          label: "Рейтингом",
          value: "RATING"
        },
        {
          label: "Корисністю",
          value: "USEFULNESS"
        }
      ],
      status: {
        approved: "Схвалено",
        rejected: "Відхилено",
        moderation: "На модерації"
      },
      statusDescription: {
        approved: "Ваш відгук схвалено",
        rejected: "Ваш відгук відхилено",
        moderation: "Ваш відгук на модерації"
      },
      modals: {
        delete: {
          success: "Ваш відгук було успішно видалено!",
          error: "Помилка видалення відгуку!",
          subtitle: "Ви впевнені, що хочете видалити цей відгук?"
        }
      }
    }
  },
  search: {
    label: "Пошук",
    placeholder: "Пошук..."
  },
  forms: {
    refundDays: {
      label: "Дні повернення",
      placeholder: "Введіть кількість днів",
      rules: {
        required: "Дні повернення обов'язкові",
        positive: "Дні повернення повинні бути більшими за 0",
        integer: "Дні повернення повинні бути цілим числом"
      }
    },
    socialLink: {
      rules: {
        https: "Посилання повинно починатися з https:// і не містити пробілів"
      }
    },
    reviewBody: {
      label: "Відгук",
      placeholder: "Введіть відгук",
      rules: {
        required: "Відгук обов'язковий",
        max: "Максимальна кількість символів 100",
        url: "URL у тексті заборонено",
        specialCharacters: "Спеціальні символи в тексті заборонені"
      }
    },
    allLanguages: {
      rules: {
        allMustBeFilled: "Заповніть для всіх мов"
      }
    },
    agree: {
      rules: {
        required: "Погодьтесь з умовами користування"
      }
    },
    site: {
      rules: {
        required: "Сайт обов'язковий"
      }
    },
    promocode: {
      label: "Промокод",
      placeholder: "Введіть промокод",
      rules: {
        required: "Промокод обов'язковий"
      }
    },
    promocodeDescription: {
      label: "Опис промокоду",
      placeholder: "Введіть опис",
      rules: {
        required: "Опис промокоду обов'язковий",
        minLength: "Мінімальна допустима кількість символом в опис: 50!",
        maxLength: "Максимальна допустима кількість символом в опис: 100!"
      }
    },
    login: {
      placeholder: "Логін",
      rules: {
        required: "Логін обов'язковий"
      }
    },
    name: {
      label: "Ваше ім'я",
      rules: {
        required: "Ім'я обов'язкове",
        max: "Ім'я не повинно бути довшим ніж 40 символів"
      }
    },
    link: {
      label: "Посилання",
      placeholder: "Введіть посилання",
      rules: {
        required: "Посилання обов'язкове",
        url: "Некоректне посилання"
      }
    },
    linkCategory: {
      label: "Тип послуги",
      placeholder: "Виберіть тип послуги",
      rules: {
        required: "Необхідний тип послуги"
      }
    },
    age: {
      rules: {
        required: "Вік обов'язковий",
        max: "Максимальний вік 99 років"
      }
    },
    proxyType: {
      placeholder: "Виберіть тип проксі",
      rules: {
        required: "Виберіть тип",
        minOne: "Оберіть хочаб один тип"
      }
    },
    countries: {
      label: "Країни",
      placeholder: {
        one: "Виберіть країну",
        many: "Виберіть країни"
      },
      rules: {
        required: "Виберіть країни"
      }
    },
    purposeUse: {
      placeholder: {
        one: "Виберіть мету використання"
      },
      rules: {
        required: "Виберіть цілі"
      }
    },
    date: {
      rules: {
        required: "Дата обов'язкова",
        allRequired: "Початкова та кінцева дата обов'язкові",
        endDateGreaterNow: "Кінцева дата повинна бути більшою за сьогодні"
      }
    },
    minRent: {
      label: "Мін. термін оренди",
      placeholder: "Виберіть мінімальний період оренди",
      rules: {
        required: "Виберіть мінімальний термін оренди"
      }
    },
    minPrice: {
      label: "Мінімальна ціна",
      placeholder: "Мін. ціна / шт",
      rules: {
        required: "Введіть мінімальну ціну",
        max: "Ціна не повинна перевищувати 12 символів",
        positive: "Ціна повинна бути більшою за 0"
      }
    },
    pullIP: {
      label: "Пул IP",
      placeholder: "Пул IP",
      rules: {
        required: "Пул IP обов'язковий",
        positive: "Пул IP повинен бути більшим за 0"
      }
    },
    minIP: {
      label: "Мін. IP",
      placeholder: "Мін. IP",
      tooltip: "Мінімальний обсяг послуги, який дозволяє придбати сервіс",
      rules: {
        required: "Мін. IP обов'язковий",
        positive: "Мін. IP повинен бути більшим за 0",
        integer: "Мін. IP повинен бути цілим числом"
      }
    },
    minGB: {
      label: "Мін. ГБ",
      placeholder: "Мін. ГБ",
      rules: {
        required: "Мін. ГБ обов'язковий",
        positive: "Мін. ГБ повинен бути більшим за 0"
      }
    },
    currency: {
      placeholder: "Виберіть валюту",
      rules: {
        required: "Виберіть валюту"
      }
    },
    speed: {
      rules: {
        number: "Значення має бути числом",
        greater: "Значення До має бути більшим за значення Від",
        positive: "Значення швидкості має бути позитивним"
      }
    },
    ping: {
      rules: {
        positive: "Значення пінга має бути позитивним"
      }
    },
    description: {
      label: "Опис",
      placeholder: "Введіть опис",
      rules: {
        required: "Напишіть опис",
        minLength: "Мінімальна допустима кількість символом в описі: 50!",
        maxLength: "Максимальна допустима кількість символом в описі: 100!",
        maxLength500: "Максимальна допустима кількість символом в описі: 500!",
        notEmpty: "Опис не може бути пустим"
      }
    },
    password: {
      placeholder: "Пароль",
      placeholderRepeat: "Повторіть пароль",
      placeholderCurrent: "Введіть поточний пароль",
      labelChangeEmail: "Пароль (якщо змінюєте email)",
      labelCurrent: "Поточний пароль",
      labelNew: "Новий пароль",
      newPassword: "Введіть новий пароль",
      state: {
        show: "Показати пароль",
        hide: "Приховати пароль"
      },
      rules: {
        required: "Пароль обов'язковий",
        min: "Мінімум символів",
        mismatch: "Паролі повинні збігатися",
        noSpace: "Пароль не може містити пробіли",
        cannotBeSame: "Новий пароль не може бути таким самим, як старий"
      }
    },
    email: {
      label: "Ваша пошта",
      rules: {
        required: "Пошта обов'язкова",
        email: "Некоректна пошта"
      }
    },
    emailV2: {
      label: "E-mail",
      placeholder: "Введіть ваш e-mail",
      rules: {
        required: "E-mail обов'язковий",
        email: "Некоректний E-mail"
      }
    },
    answer: {
      label: "Ваша відповідь",
      rules: {
        required: "Відповідь обов'язкова"
      }
    },
    affiliatePercent: {
      rules: {
        max: "Відсоток не може бути більшим за 3 символи"
      }
    },
    message: {
      label: "Повідомлення",
      placeholder: "Введіть текст...",
      rules: {
        required: "Заповніть повідомлення"
      }
    },
    comment: {
      label: "Коментар",
      placeholder: "Введіть коментар",
      rules: {
        required: "Заповніть коментар"
      }
    },
    field: {
      rules: {
        required: "Поле обов'язкове"
      }
    },
    common: {
      rules: {
        required: "Виберіть значення"
      }
    },
    addComment: {
      btn: "Додати коментар"
    },
    buttons: {
      submit: "Відправити",
      cancel: "Скасувати",
      save: "Зберегти",
      delete: "Видалити",
      edit: "Редагувати",
      add: "Додати",
      saveChanges: "Зберегти зміни",
      confirm: "Підтвердити",
      send: "Надіслати"
    },
    noOptions: "Немає варіантів"
  },
  reviews: {
    anonymous: "Анонім",
    answers: "Відповіді",
    answer: "Відповісти",
    showMore: "Показати щє",
    showFull: "Показати повністю",
    showLess: "Сховати",
    you: "Ви",
    expert: "Відповідь експерта",
    showAll: "Показати всі відповіді",

    content: {
      advantages: {
        title: "Переваги"
      },
      disadvantages: {
        title: "Недоліки"
      },
      review: {
        title: "Коментар"
      },
      usage: {
        title: "Де використали"
      },
      wishes: {
        title: "Побажання продавцю"
      },
      reason: {
        title: "Причина"
      }
    },
    reply: {
      apply: "Відповісти",
      cancel: "Скасувати",
      toggle: {
        close: "Згорнути",
        open: "Розгорнути"
      }
    },
    voting: {
      title: "Проголосувати"
    },
    feedback: {
      title: "Відгук корисний"
    },
    rating: {
      title: "Рейтинг",
      from: "з"
    },
    translator: {
      status: {
        translated: "Перекладено",
        original: "Оригінал"
      },
      showOriginal: "Показати оригінал",
      translate: "Перекласти"
    }
  },
  common: {
    yes: "Так",
    no: "Ні"
  },
  validation: {
    valid: {
      title: "Валідний",
      report: "Домен валідний!"
    },
    invalid: {
      title: "Невалідний",
      report: "Домен невалідний!",
      panel: {
        title: "Валідація домену для",
        notice:
          "Щоб підтвердити домен, використовуйте один із способів, наведених нижче.",
        subtitle: "Як підтвердити домен",
        link: {
          title: "У корені сайту додайте файл завантажений",
          linkName: "тут",
          example: "Шлях до файлу має бути таким:"
        },
        metag: {
          title: "Або додайте метатег на сайт:"
        },
        nextData: {
          title: "Дата наступної валідації:"
        }
      }
    }
  },
  statuses: {
    added: "Доданий",
    active: "Активний",
    inactive: "Не активний",
    title: "Статус",
    moderation: "На модерації"
  },
  statistic: {
    views: {
      title: "Переглядів"
    },
    conversions: {
      title: "Переходів"
    },
    reviews: {
      title: "Відгуків"
    }
  },
  uploader: {
    chooseFile: "Натисніть, щоб завантажити",
    chooseFileSecond: "або перетягніть його сюди",
    or: "або",
    chooseOtherFile: "Виберіть інший файл",
    staticState: "або перетягніть його сюди. (до 2 МБ)",
    draggingState: "Відпустіть файл, щоб завантажити його",
    format: "PNG або JPG 256x256 px (макс. розмір 2 МБ)"
  },
  modals: {
    review: {
      titleAdd: "Залишити відгук",
      titleEdit: "Редагувати відгук",
      titleDelete: "Ви впевнені, що хочете видалити відгук?",
      edit: "Редагувати",
      delete: "Видалити",
      cancel: "Скасувати",
      form: {
        ratings: {
          title: "Оцініть сервіс",
          rating: "Рейтинг",
          speed: "Швидкість",
          reliability: "Надійність",
          support: "Підтримка",
          price: "Ціна"
        },
        personalInfo: {
          title: "Особиста інформація",
          name: "Ім'я",
          email: "E-mail"
        },
        advantages: "Переваги",
        disadvantages: "Недоліки",
        review: "Коментар",
        usage: "Де використали",
        wishes: "Побажання продавцю",
        rating: "Оберіть рейтинг",
        placeholder: "Введіть текст",
        success: {
          title: "Дякуємо!",
          text: "Ваш відгук прийнято. Найближчим часом ми його опублікуємо!"
        }
      }
    },
    complaint: {
      title: "Залишити скаргу",
      form: {
        reason: "Причина",
        body: "Скарга"
      }
    },
    comment: {
      title: "Залишити коментар",
      form: {
        name: {
          title: "Ім'я користувача",
          placeholder: "Ім'я"
        },
        email: {
          title: "E-mail",
          placeholder: "rating.proxy@mail.com"
        },
        body: {
          title: "Коментар",
          placeholder: "Текст коментаря"
        }
      }
    },
    email: {
      title: "Підтвердження пошти",
      confirmed: "Вашу пошту успішно підтверджено!",
      notConfirmed:
        "На жаль, не вдалося підтвердити вашу пошту! Зареєструйтесь ще раз."
    },
    subscribe: {
      title: "Підписка на нові промокоди",
      description: "Ми надішлемо вам нові промокоди на електронну пошту",
      success: "Ви успішно підписалися на нові промокоди!",
      info: "Ви вже підписані на нові промокоди цього сервісу!",
      form: {
        email: {
          title: "E-mail",
          placeholder: "Введіть ваш e-mail"
        },
        name: {
          title: "Ім'я",
          placeholder: "Введіть ваше ім'я"
        },
        btn: "Підписатися",
        close: "Закрити"
      }
    }
  },
  yesno: [
    {
      value: true,
      label: "Так"
    },
    {
      value: false,
      label: "Ні"
    }
  ],
  times: {
    hour: ["година", "години", "годин"],
    day: ["день", "дні", "днів"],
    week: ["тиждень", "тижні", "тижнів"],
    month: ["місяць", "місяці", "місяців"],
    year: ["рік", "роки", "років"]
  },
  proxyItems: {
    countries: {
      title: "Проксі за країнами",
      list: {
        more: "Більше",
        countries: "країн",
        proxies: "проксі сервісів"
      }
    },
    byType: {
      title: "Проксі за типом"
    },
    social: {
      title: "Проксі для соц. мереж",
      additionalText: ["та інші"]
    },
    seo: {
      title: "Проксі для SEO",
      additionalText: ["та інші"]
    },
    mobile: {
      title: "Мобільні проксі",
      list: {
        countries: "країн",
        operators: "операторів",
        proxies: "проксі сервісів"
      }
    },
    shared: {
      title: "Shared проксі",
      list: {
        countries: "країн",
        proxies: "проксі сервісів"
      }
    },
    unknownTile: {
      title: "Невідома плитка"
    }
  },
  countries: [
    {
      code: "EGY",
      name: "Єгипет",
      nameGradient: "Єгипту"
    },
    {
      code: "AUS",
      name: "Австралія",
      nameGradient: "Австралії"
    },
    {
      code: "AUT",
      name: "Австрія",
      nameGradient: "Австрії"
    },
    {
      code: "GBR",
      name: "Великобританія",
      nameGradient: "Великобританії"
    },
    {
      code: "AM",
      name: "Вірменія",
      nameGradient: "Вірменії"
    },
    {
      code: "BLR",
      name: "Білорусь",
      nameGradient: "Білорусії"
    },
    {
      code: "BGR",
      name: "Болгарія",
      nameGradient: "Болгарії"
    },
    {
      code: "BRA",
      name: "Бразилія",
      nameGradient: "Бразилії"
    },
    {
      code: "VEN",
      name: "Венесуела",
      nameGradient: "Венесуели"
    },
    {
      code: "DEU",
      name: "Німеччина",
      nameGradient: "Німеччини"
    },
    {
      code: "GEO",
      name: "Грузія",
      nameGradient: "Грузії"
    },
    {
      code: "EUROPE",
      name: "Європа",
      nameGradient: "Європи"
    },
    {
      code: "IND",
      name: "Индия",
      nameGradient: "Индии"
    },
    {
      code: "ESP",
      name: "Іспанія",
      nameGradient: "Іспанії"
    },
    {
      code: "ITA",
      name: "Італія",
      nameGradient: "Італії"
    },
    {
      code: "KAZ",
      name: "Казахстан",
      nameGradient: "Казахстану"
    },
    {
      code: "CAN",
      name: "Канада",
      nameGradient: "Канади"
    },
    {
      code: "KGZ",
      name: "Киргизія",
      nameGradient: "Киргизії"
    },
    {
      code: "CHN",
      name: "Китай",
      nameGradient: "Китаю"
    },
    {
      code: "KOR",
      name: "Південна Корея",
      nameGradient: "Південної Кореї"
    },
    {
      code: "LVA",
      name: "Латвія",
      nameGradient: "Латвії"
    },
    {
      code: "LTU",
      name: "Литва",
      nameGradient: "Литви"
    },
    {
      code: "LIE",
      name: "Ліхтенштейн",
      nameGradient: "Ліхтенштейну"
    },
    {
      code: "MEX",
      name: "Мексика",
      nameGradient: "Мексики"
    },
    {
      code: "MDA",
      name: "Молдова",
      nameGradient: "Молдови"
    },
    {
      code: "MCO",
      name: "Монако",
      nameGradient: "Монако"
    },
    {
      code: "NLD",
      name: "Нідерланди",
      nameGradient: "Нідерландів"
    },
    {
      code: "NOR",
      name: "Норвегія",
      nameGradient: "Норвегії"
    },
    {
      code: "POL",
      name: "Польща",
      nameGradient: "Польщи"
    },
    {
      code: "RUS",
      name: "Росія",
      nameGradient: "Росії"
    },
    {
      code: "SYC",
      name: "Сейшели",
      nameGradient: "Сейшел"
    },
    {
      code: "SGP",
      name: "Сінгапур",
      nameGradient: "Сінгапуру"
    },
    {
      code: "SVN",
      name: "Сальвадор",
      nameGradient: "Сальвадору"
    },
    {
      code: "USA",
      name: "США",
      nameGradient: "США"
    },
    {
      code: "TUR",
      name: "Туреччина",
      nameGradient: "Туреччини"
    },
    {
      code: "UKR",
      name: "Україна",
      nameGradient: "України"
    },
    {
      code: "FIN",
      name: "Фінляндія",
      nameGradient: "Фінляндії"
    },
    {
      code: "FRA",
      name: "Франція",
      nameGradient: "Франції"
    },
    {
      code: "MNE",
      name: "Чорногорія",
      nameGradient: "Чорногорії"
    },
    {
      code: "CZE",
      name: "Чехія",
      nameGradient: "Чехії"
    },
    {
      code: "CHL",
      name: "Чилі",
      nameGradient: "Чилі"
    },
    {
      code: "CHE",
      name: "Швейцарія",
      nameGradient: "Швейцарії"
    },
    {
      code: "SWE",
      name: "Швеція",
      nameGradient: "Швеції"
    },
    {
      code: "LKA",
      name: "Шрі-Ланка",
      nameGradient: "Шрі-Ланки"
    },
    {
      code: "JAM",
      name: "Ямайка",
      nameGradient: "Ямайки"
    },
    {
      code: "JPN",
      name: "Японія",
      nameGradient: "Японії"
    }
  ],
  proxyTypesGen: {
    IPv4: "IPv4",
    IPv6: "IPv6",
    ISP: "ISP",
    FreeProxy: "Безкоштовних",
    MobileProxy: "Мобільних",
    SharedProxy: "Загальних",
    ResidentialProxy: "Резидентських",
    RemoteServer: "Віддалених"
  },
  ui: {
    readReview: "Читати відгуки",
    yesNo: {
      yes: "Так",
      no: "Ні"
    },
    export: "Експорт:",
    step: "Крок",
    on: "на"
  },
  fingerprint: {
    main: {
      title: "Анонімна ідентифікація браузерів",
      subtitle:
        "Ви унікальні? Дізнайтеся властивості ідентифікації вашого браузера",
      buttonLabel: "Перевірити свої відбитки"
    },
    httpAttributes: "Атрибути заголовків HTTP",
    searchAttributes: "Пошук атрибутів",
    javaScriptAttributes: "Атрибути JavaScript",
    table: {
      attribute: "Атрибути",
      similarityRatio: "Коефіцієнт подібності",
      value: "Значення",
      and: "Та",
      others: "Інших",
      noValue: "Немає значення"
    },
    faq: "Запитання й відповіді",
    HTTP: {
      userAgent: "User Agent",
      userAgentTooltip: "Показує інформацію про браузер та пристрій",
      accept: "Допустимі типи даних",
      acceptTooltip:
        "HTTP-заголовок, що вказує на типи медіа, які клієнт готовий отримувати від сервера.",
      contentEncoding: "Кодування контенту",
      contentEncodingTooltip:
        "HTTP-заголовок, що вказує на перетворення кодування, які були застосовані до контенту.",
      contentLanguage: "Мова контенту",
      contentLanguageTooltip:
        "HTTP-заголовок, що вказує на мову(и) контенту, наданого сервером.",
      upgradeInsecureRequests: "Оновлення незахищених запитів",
      upgradeInsecureRequestsTooltip:
        "HTTP-заголовок, що вказує, що клієнт надає перевагу отриманню захищених (HTTPS) версій ресурсів.",
      ifNoneMatch: "If none match",
      ifNoneMatchTooltip:
        "Заголовок запиту HTTP If-None-Match використовується для перетворення запиту HTTP в запит, який є умовним на основі набору заголовків HTTP ETag, які не збігаються.",
      referer: "Referer",
      refererTooltip:
        "Referer — це необов’язкове поле заголовка HTTP, яке визначає адресу веб-сторінки, з якої запитується ресурс."
    },
    JS: {
      userAgentJS: "User Agent",
      userAgentJSTooltip: "Показує інформацію про браузер та пристрій",
      os: "Операційна система",
      osTooltip: "Програмне забезпечення, що керує комп'ютером або пристроєм",
      cookie: "Cookies увімкнено",
      cookieTooltip:
        "Невеликий файл із даними, який сайт зберігає в браузері. Використовується для збереження налаштувань користувача та сесій.",
      timeZone: "Часовий пояс",
      timeZoneTooltip:
        "Часовий стандарт для регіону, який використовується пристроєм або додатком.",
      languageJs: "Мова контенту",
      languageJsTooltip:
        "Мова програмування або сценаріїв, що використовується на сайті або в додатку.",
      navigatorProperties: "Властивості навігатора",
      navigatorPropertiesTooltip: "Властивості навігатора",
      canvas: "Canvas",
      canvasTooltip:
        "Процес збору думок або даних, зазвичай через опитування або прямі взаємодії.",
      fonts: "Список шрифтів (JS)",
      fontsTooltip: "Список шрифтів, що використовуються в додатку (JS).",
      adblock: "Використання Adblock",
      adblockTooltip:
        "Інструмент або розширення, яке запобігає відображенню реклами в браузері.",
      doNotTrack: "Не відслідковувати",
      doNotTrackTooltip:
        "Функція конфіденційності, яка запобігає відстеженню вашої діяльності в Інтернеті.",
      buildID: "BuildID",
      buildIDTooltip:
        "Унікальний ідентифікатор для конкретної збірки або версії додатка.",
      product: "Продукт",
      productTooltip:
        "Конкретний товар або послуга, яку пропонує компанія або додаток.",
      productSub: "Субпродукт",
      productSubTooltip:
        "Підкатегорія або конкретний варіант основного продукту.",
      vendor: "Постачальник",
      vendorTooltip:
        "Компанія або особа, яка постачає або продає товар чи послугу.",
      vendorSub: "Субпостачальник",
      vendorSubTooltip:
        "Підкатегорія або конкретний варіант, пов'язаний з основним постачальником.",
      hardware: "Конкурентність обладнання",
      hardwareTooltip:
        "Кількість логічних процесорів або ядер, доступних на пристрої.",
      java: "Java увімкнено",
      javaTooltip: "Вказує, чи увімкнено Java у браузері або додатку.",
      memory: "Пам'ять пристрою",
      memoryTooltip:
        "Кількість оперативної пам'яті (RAM), доступної на пристрої.",
      plugins: "Список плагінів",
      pluginsTooltip: "Список встановлених плагінів браузера або додатку.",
      screenWidth: "Ширина екрану",
      screenWidthTooltip:
        "Загальна ширина екрану, включаючи всі видимі області.",
      screenHeight: "Висота екрану",
      screenHeightTooltip:
        "Загальна висота екрану, включаючи всі видимі області.",
      screenDepth: "Глибина кольору екрану",
      screenDepthTooltip:
        "Кількість бітів, що використовуються для представлення кольору кожного пікселя на екрані.",
      availableTop: "Доступна верхня частина екрану",
      availableTopTooltip:
        "Вертикальна відстань від верхньої частини екрана до верхнього краю області, доступної для контенту, без урахування системних елементів.",
      availableLeft: "Доступна ліворуч частина екрану",
      availableLeftTooltip:
        "Горизонтальна відстань від лівого краю екрана до лівого краю області, доступної для контенту, без урахування системних елементів.",
      availableHeight: "Доступна висота екрану",
      availableHeightTooltip:
        "Вертикальна відстань, доступна для контенту на екрані, без урахування областей, зайнятих системними елементами.",
      availableWidth: "Доступна ширина екрану",
      availableWidthTooltip:
        "Горизонтальна відстань, доступна для контенту на екрані, без урахування областей, зайнятих системними елементами.",
      permissions: "Дозволи",
      permissionsTooltip:
        "Список дозволів, наданих або запитуваних додатком чи сайтом.",
      webGLVendor: "Постачальник WebGL",
      webGLVendorTooltip:
        "Постачальник або виробник реалізації WebGL, що використовується браузером або додатком.",
      webGLRenderer: "Рендерер WebGL",
      webGLRendererTooltip:
        "Конкретний рендерер або графічний двигун, що використовується WebGL для відображення графіки в браузері або додатку.",
      webGLData: "Дані WebGL",
      webGLDataTooltip:
        "Інформація про контекст WebGL і дані, які обробляються або використовуються для відображення.",
      webGLParameters: "Параметри WebGL",
      webGLParametersTooltip:
        "Налаштування та параметри для контексту WebGL, включаючи деталі про відображення та продуктивність.",
      useOfLocalStorage: "Використання локального сховища",
      useOfLocalStorageTooltip:
        "Вказує, чи використовує додаток або сайт локальне сховище браузера для збереження даних.",
      useOfSessionStorage: "Використання сеансового сховища",
      useOfSessionStorageTooltip:
        "Вказує, чи використовує додаток або сайт сеансове сховище браузера для тимчасового збереження даних.",
      useOfIndexedDB: "Використання IndexedDB",
      useOfIndexedDBTooltip:
        "Вказує, чи використовує додаток або сайт IndexedDB для збереження великої кількості структурованих даних.",
      audioFormats: "Аудіоформати",
      audioFormatsTooltip:
        "Список аудіоформатів, які підтримуються або використовуються додатком чи сайтом.",
      audioContext: "Аудіоконтекст",
      audioContextTooltip:
        "Інтерфейс, який використовується для управління та контролю аудіообробки та маршрутизації в додатку або на сайті.",
      frequencyAnalyser: "Аналізатор частот",
      frequencyAnalyserTooltip:
        "Інструмент або функція, яка аналізує і відображає частотні компоненти аудіосигналів.",
      audioData: "Аудіо дані",
      audioDataTooltip:
        "Сирі або оброблені дані, що представляють аудіосигнали, які використовуються для відтворення або аналізу.",
      videoFormats: "Відео формати",
      videoFormatsTooltip:
        "Список відеоформатів, які підтримуються або використовуються додатком чи сайтом.",
      mediaDevices: "Медійні пристрої",
      mediaDevicesTooltip:
        "Список медійних пристроїв, таких як камери, мікрофони та динаміки, доступних для додатка або сайту.",
      accelerometer: "Акселерометр",
      accelerometerTooltip:
        "Датчик, який вимірює сили прискорення, що діють на пристрій, для виявлення руху та орієнтації.",
      gyroscope: "Гіроскоп",
      gyroscopeTooltip:
        "Датчик, який вимірює швидкість обертання навколо осей пристрою для визначення орієнтації та кутової швидкості.",
      proximitySensor: "Датчик наближення",
      proximitySensorTooltip:
        "Датчик, який виявляє наявність сусідніх об'єктів без фізичного контакту, зазвичай використовується для визначення близькості до пристрою.",
      keyboardLayout: "Розклад клавіатури",
      keyboardLayoutTooltip:
        "Розташування клавіш на клавіатурі, включаючи тип використовуваної розкладки (наприклад, QWERTY, AZERTY).",
      battery: "Акумулятор",
      batteryTooltip: "Інформація про стан акумулятора пристрою",
      connection: "З'єднання",
      connectionTooltip:
        "Інформація про тип і статус мережевого з'єднання, наприклад, Wi-Fi, мобільний зв'язок або Ethernet.",
      key: "Ключ",
      keyTooltip:
        "Унікальний ідентифікатор або інформація, що використовується для доступу до даних або сервісів.",
      locationBar: "Панель адреса",
      locationBarTooltip:
        "Частина інтерфейсу браузера, де відображається та може редагуватися URL поточної веб-сторінки.",
      menuBar: "Панель меню",
      menuBarTooltip:
        "Частина інтерфейсу додатка або браузера, яка містить меню для доступу до різних функцій та налаштувань.",
      personalBar: "Персональна панель",
      personalBarTooltip:
        "Налаштовувана область в інтерфейсі браузера, де користувачі можуть додавати ярлики до своїх улюблених сайтів або інструментів.",
      statusBar: "Панель стану",
      statusBarTooltip:
        "Область інтерфейсу браузера або додатка, яка відображає інформацію про поточний стан, наприклад, про процес завантаження або сповіщення.",
      toolBar: "Панель інструментів",
      toolBarTooltip:
        "Частина інтерфейсу додатка або браузера, яка забезпечує доступ до різних інструментів та функціональностей, часто у вигляді кнопок або значків.",
      resultState: "Стан результату",
      resultStateTooltip: "Поточний статус або стан результату",
      listOfFontsFlash: "Список шрифтів (Flash)",
      listOfFontsFlashTooltip:
        "Список шрифтів, доступних у Adobe Flash або Flash Player, який може включати як системні, так і вбудовані шрифти.",
      screenResolutionFlash: "Розширення екрана (Flash)",
      screenResolutionFlashTooltip:
        "Налаштування розширення екрана, що використовуються Adobe Flash або Flash Player, яке вказує ширину та висоту області відображення.",
      languageFlash: "Мова (Flash)",
      languageFlashTooltip:
        "Налаштування мови, що використовуються Adobe Flash або Flash Player, що вказує мову контенту або інтерфейсу користувача.",
      platformFlash: "Платформа (Flash)",
      platformFlashTooltip:
        "Налаштування платформи або операційної системи, що використовуються Adobe Flash або Flash Player, які вказують на середовище, в якому працює Flash."
    }
  },
  portScanner: {
    main: {
      title: "Сканер портів онлайн",
      subtitle:
        "Port Checker - це простий і безкоштовний онлайн-інструмент для перевірки відкритих портів на вашому комп'ютері/пристрої, який часто корисний під час перевірки налаштувань переадресації портів на маршрутизаторі."
    },
    testPort: "Перевірте свій порт:",
    form: {
      loading: "Завантаження",
      scan: "Cканувати",
      pasteIP: "Вставити мою IP-адресу",
      inputLabel: "IP-адреса або домен:",
      selectLabel: "Тип сканування портів",
      infoMessage:
        "Якщо результат «Хост, здається, не працює», це означає, що брандмауер або маршрутизатор цілі перевіряється, а IP-адреса блокує ping",
      popularPorts: "Популярні порти",
      serverPorts: "Серверні порти",
      gamePorts: "Ігрові порти",
      applicationPorts: "Порти програми",
      p2pPorts: "P2P порти",
      rules: {
        required: "IP-адреса або домен обов'язковий",
        ip: "Неправильна IP-адреса або домен"
      }
    },
    checkResults: "Результати перевірки:",
    open: "відкритий",
    closed: "закритий",
    notShown: "Не показано:",
    ipDomain: "IP/ Домен:",
    portChecked: {
      title: "Які порти перевіряються",
      port: "Порт",
      protocol: "Протокол",
      description: "Опис",
      tooltip:
        "Виберіть, у якому форматі ви хочете експортувати результати перевірки"
    },
    sliderTitle: "Що робить онлайн-сканер портів:",
    cta: {
      title: "Які порти скануються",
      description:
        "Наш сервіс сканує найпопулярніші порти та приховані порти. Повний їх список можна побачити після перевірки в контекстному блоці праворуч.",
      buttonLabel: "Спробуйте сканер портів онлайн"
    },
    stepGuide: "Як сканувати порти — покрокове керівництво"
  },
  tracingIp: {
    main: {
      title: "Трассування IP онлайн",
      subtitle:
        "Відстеження IP-адрес онлайн — це інструмент діагностики, який відображає інформацію про всі проміжні маршрутизатори на шляху до кінцевого вузла. Для цього скористайтеся нашим онлайн-сервісом. Ви можете використовувати цей інструмент діагностики безкоштовно необмежену кількість разів."
    },
    form: {
      traceIP: "Відстежити IP",
      loading: "Завантаження",
      inputLabel: "Введіть дійсну IP-адресу (0.0.0.0) або ім'я хоста",
      enterIP: "Введіть IP",
      selectLabel: "Показати маршрут роута за країною",
      infoMessage: "Відстеження може тривати до кількох хвилин",
      rules: {
        required: "IP-адреса або ім'я хоста обов'язкове",
        ip: "Неправильна IP-адреса або ім'я хоста"
      }
    }
  },
  dnsLeak: {
    main: {
      title: "Тест на витік DNS",
      subtitle:
        "Тест на витік DNS – це інструмент, який використовується для визначення того, які DNS-сервери використовує ваш браузер для визначення доменних імен."
    },
    analyzing: "Аналіз...",
    result: "Результат:",
    vulnerable: "ВРАЗЛИВИЙ",
    verify:
      "Переконайтеся, що ваш Інтернет-трафік направляється через вибраний DNS",
    notDetected:
      "Витік DNS не виявлено, але для безпеки скористайтеся TrustyTech",
    stopDns: "Зупинити витік DNS",
    runDns: "Запустити тест на витік DNS",
    yourIP: "Ваш IP:",
    pleaseWait: "Будь ласка, зачекайте кілька секунд",
    table: {
      ip: "ip",
      provider: "Провайдер",
      country: "Країна"
    },
    whatIsDns: {
      title: "Що таке тест на витік DNS",
      description:
        "DNS допомагає користувачам легко отримувати доступ до веб-сайтів та інших інтернет-ресурсів. Ваш пристрій надсилає запити на DNS-сервер, а сервер надсилає назад вказівки до того, що ви шукаєте. Саме це робить DNS значним ризиком для конфіденційності."
    },
    whatDoesDns: {
      title: "Що показує перевірка DNS",
      description:
        "Перевірка на наявність витоків DNS допомагає захистити вашу конфіденційність і гарантувати, що ваші дії в Інтернеті не стануть доступними для неавторизованих і потенційно зловмисних сторін. Ви повинні регулярно перевіряти наявність витоків DNS, щоб переконатися, що ваші DNS-запити надходять туди, куди вони повинні, і що ваша онлайн-діяльність залишається конфіденційною."
    },
    howFixDns: {
      title: "Як виправити витік DNS"
    }
  },
  ipBlacklist: {
    main: {
      title: "Перевірка чорного списку IP",
      subtitle:
        "Як відомо, через спам часто IP-адреса потрапляє в чорний список, що помітно порушує роботу мережі. Щоб цього уникнути, слід використовувати сервер перевірки IP-адрес у спам-базах."
    },
    ipCheckserver: "Сервер перевірки IP",
    form: {
      examination: "Перевірка",
      loading: "Завантаження",
      enterIp: "Введіть IP",
      label: "Введіть правильну IP-адресу:",
      infoMessage: "Тест може зайняти деякий час.",
      rules: {
        required: "IP-адреса обов'язкова",
        ip: "Неправильна IP-адреса"
      }
    },
    checks: "Перевірки:",
    checkedOut: "Перевірено:",
    blacklisted: "У чорному списку:",
    listed: "Є в списку",
    notListed: "Немає в списку"
  },
  freeProxy: {
    main: {
      title: "Безкоштовний проксі-сервер онлайн",
      subtitle:
        "Ми надаємо списки безкоштовних проксі-серверів з інформаційною метою. Наші регулярно оновлювані списки містять безкоштовні IP-адреси з понад 150 країн. Однак безкоштовні проксі не підходять для звичайних серйозних завдань. Для таких цілей ми рекомендуємо ознайомитися з нашою службою <a href='https://proxy-sale.com/'>персональних проксі-серверів</a>"
    },
    list: {
      title: "Список безкоштовних проксі",
      itemsFound: "Знайдено проксі:",
      tooltip: "Виберіть, у якому форматі потрібно експортувати список проксі",
      infoMessage:
        "Списки безкоштовних проксі-серверів, надані цією службою, є загальнодоступними даними, отриманими шляхом аналізу загальнодоступних сторінок Інтернету, і надаються лише в інформаційних цілях. Використання безкоштовних проксі-списків здійснюється виключно на власний страх і ризик."
    },
    filter: {
      country: "Країна",
      selectCountry: "Виберіть країну",
      city: "Місто",
      selectCity: "Виберіть місто",
      typeOf: "Тип",
      allCountries: "Всі країни",
      allCities: "Всі міста",
      allTypes: "Всі типи"
    },
    table: {
      ipAddress: "IP-адреса",
      port: "Порт",
      anonymity: "Анонімність",
      lastChecked: "Остання перевірка",
      speed: "Скорость"
    },
    howUseProxy: {
      title: "Як користуватися безкоштовним проксі",
      description:
        "Безкоштовний проксі став особливо популярним, коли виникла масова потреба в обході блокування популярних веб-ресурсів - соціальних мереж, відеохостингів та інших.",
      listTitle:
        "Таким чином, ви можете використовувати безкоштовний проксі для будь-яких цілей:"
    },
    differenceBetween: {
      title: "Різниця між безкоштовним і персональним проксі-серверами",
      description:
        "На перший погляд, персональний і безкоштовний проксі-сервери однакові, оскільки принципи їх роботи схожі: щоб «обдурити» провайдера або систему безпеки сайту, IP реального користувача «прикривається» проміжним IP-проксі. Однак насправді різниця досить велика:",
      freeProxy: "Безкоштовний проксі",
      personalProxy: "Персональний проксі"
    },
    howDownloadProxy: {
      title: "Як завантажити список проксі"
    }
  },
  myAnonymity: {
    main: {
      title: "Моя анонімність",
      subtitle:
        "Служба перевірки анонімності IP-адреси надає вичерпну інформацію, до якої різні служби або веб-сайти можуть отримати доступ під час відвідування або використання. Разом ці дані можуть значно знизити сприйману достовірність IP-адреси. Для ретельної перевірки анонімності бажано дотримуватися основних рекомендації сервісу для анонімних та безпечних операцій."
    },
    table: {
      provider: "Провайдер",
      hostname: "Ім'я хоста",
      os: "ОС",
      browser: "Браузер",
      dns: "DNS",
      proxy: "Проксі",
      anonymizer: "Анонімайзер",
      blacklist: "Чорний список"
    },
    myIP: "Мій IP:",
    yourDisguise: "Ваше маскування:",
    title: "Почати перевіряти анонімність",
    subtitle:
      "Після детальної перевірки на екрані відобразяться всі основні пункти, що визначають рівень анонімності вашого IP",
    buttonLabel: "Перевірити мою анонімність",
    info: "Помірна безпека та зауваження щодо анонімності",
    details: {
      title: "Деталі IP-адреси",
      location: "Розташування",
      language: "Мова",
      browser: "Браузер",
      time: "Час",
      socialMedia: "Соціальні мережі",
      interactiveDetection: "Інтерактивне виявлення",
      navigator: "Навігатор",
      screen: "Екран",
      scripts: "Скрипти",
      plugins: "Плагіни",
      hide: "Приховати",
      showMore: "Показати більше",
      enabled: "увімкнено",
      disabled: "вимкнено",
      country: "Країна",
      region: "Регіон",
      city: "Місто",
      zip: "Поштовий індекс",
      hostname: "Ім'я хоста",
      reversed: "Зворотній IP",
      ipRange: "Діапазон IP",
      isp: "Інтернет-провайдер",
      organization: "Організація",
      asOrganization: "AS Організація",
      asNumber: "AS Номер",
      headers: "Заголовки",
      zone: "Зона",
      local: "Локальний",
      system: "Системний",
      ipAddress: "Ip-адреса",
      ports: "Порти",
      openPorts: "Відкриті порти",
      platform: "Платформа",
      height: "Висота",
      width: "Ширина",
      windowSize: "Розмір екрану",
      os: "ОС"
    },
    whatIsAnonymity: {
      title: "Що таке перевірка анонімності для IP або проксі",
      description:
        "За допомогою цієї служби ви можете перевірити, наскільки ви анонімні в мережі, скільки даних надає ваш комп’ютер/браузер, ті самі дані, які надає ваша ip-адреса. Крім того, послугу можна використовувати для перевірки вразливостей, які впливають на анонімність IP."
    },
    whatDoesAnonymity: {
      title: "Що робить перевірка анонімності",
      description: `Інструмент перевірки точно визначає анонімність проксі, показує його використання та справжній тип користувача. Отримавши таку інформацію, клієнт може або підтвердити свою безпеку, або (за її відсутності) надати її собі в найкоротші терміни.
      Використовуючи цей сервіс, ви можете перевірити ступінь анонімності в мережі, а також те, що дані, надані вашим комп’ютером / браузером, збігаються з даними, наданими вашою IP-адресою.`
    }
  },
  webRTCLeakTest: {
    main: {
      title: "Тест витоку WebRTC",
      subtitle:
        "Якщо WebRTC увімкнено у вашому браузері, і ви перебуваєте на веб-сайті, який його використовує, ваша справжня IP-адреса буде видимою, і, отже, вона буде витікати. Перевірте, чи WebRTC увімкнено у вашому браузері та витікає ваші справжню IP-адресу."
    },
    table: {
      ip: "IP",
      type: "Тип",
      status: "Статус",
      leak: "Потенційний витік",
      noLeak: "Немає витоку",
      leakTooltip:
        "Ваша особиста інформація може бути скомпрометована, навіть якщо ви використовуєте VPN.",
      noLeakTooltip: "Ваша особиста інформація захищена"
    },
    webRTCTest: {
      title: "IP-адреси WebRTC, які розкриваються",
      buttonLabel: "Запобігти витоку WebRTC",
      leakMessage: "Ваш браузер надає IP-адреси сайтам, які ви відвідуєте.",
      noLeakMessage:
        "Чудово! Протокол WebRTC вимкнено, тому ваша IP-адреса захищена."
    },
    whatIsWebRTC: {
      title: "Що таке WebRTC",
      description:
        "WebRTC або веб-зв'язок у реальному часі дає веб-браузерам можливість спілкуватися безпосередньо без стороннього сервера. Це означає високу швидкість і час відповіді під час перегляду Інтернету. Це важливо для таких речей, як прямі трансляції на таких сервісах, як Twitch або будь-який інший сервіс, який залежить від швидкості та часу ping."
    },
    whatDoesWebRTC: {
      title: "Що робить тест на витік WebRTC",
      description:
        "Цей інструмент повідомить вам, чи ваші справжні загальнодоступні IP-адреси незахищені. Ви зможете побачити IP-адреси, зібрані WebRTC, і передавати їх вам, щоб ви могли пов’язати їх із своєю публічною IP-адресою."
    },
    howFixWebRTC: {
      title: "Як виправити витік WebRTC"
    }
  },
  httpHeadersChecker: {
    main: {
      title: "Перевірка заголовків HTTP",
      subtitle:
        "Перевірте заголовки, рекламовані вашим веб-сервером або мережевим пристроєм"
    },
    httpHeadersCheck: "Перевірка заголовка HTTP",
    site: "Сайт",
    ipAddress: "IP-адреса",
    form: {
      enterWebsite: "Введіть URL-адресу веб-сайту",
      selectCountry: "Виберіть країну",
      button: "Перевірити заголовки",
      info: "Тест може зайняти деякий час.",
      rules: {
        required: "Веб-сайт є обов'язковим",
        url: "Неправильный URL"
      }
    },
    table: {
      header: "Заголовок",
      value: "Значення"
    }
  },
  evercookieTest: {
    main: {
      title: "Тест Evercookie",
      subtitle:
        "Evercookie створено для захисту даних, які мають бути постійними, зберігаючи ці дані в кількох місцях одночасно. У разі втрати будь-яких даних (наприклад, якщо ви очистили файли cookie), їх можна відновити, а потім скинути і повторно використати"
    },
    testResult: "Результат тесту Evercookie",
    infoCreate:
      "Натисніть, щоб створити evercookie. Ваша анонімність не буде порушена",
    buttonCreate: "Створити Evercookies",
    uidNotSet: "ЗАРАЗ НЕ ВСТАНОВЛЕНО",
    cookieFound: "ЗНАЙДЕНИЙ COOKIE UID",
    infoRediscover:
      "Будь ласка, спробуйте видалити цей файл cookie «uid» будь-яким можливим способом, а потім натисніть кнопку нижче",
    buttonRediscover: "Знайти файли cookie",
    table: {
      name: "Назва",
      value: "Значення"
    }
  },
  browserInformation: {
    main: {
      title: "Інформація про браузер",
      subtitle:
        "Онлайн-пошук інформації про браузер - це інструмент, який допоможе вам знайти інформацію про веб-браузер і визначити вашу IP-адресу"
    },
    youreUsing: "Ви використовуєте",
    yourIP: "Ваш IP:",
    table: {
      os: "Операційна система",
      ip: "IP",
      cms: "CMS",
      language: "Мова",
      userAgent: "UserAgent",
      application: "Версія програми"
    }
  },
  faq: {
    heading: "Загальні поширені запитання",
    description:
      "Усе, що вам потрібно знати про продукт і його роботу. Не можете знайти відповідь? <a href='/ua/feedback/'>Зв'яжіться з нами</a>"
  },
  proxyFilters: {
    rating: "За рейтингом",
    reviews: "За відгуками",
    costs: "За вартістю"
  },
  buttons: {
    viewMore: "Показати більше"
  },
  noData: {
    select: {
      default: "Немає даних"
    }
  },
  layouts: {
    auth: {
      registration: {
        title: "Почніть створювати відгуки про проксі на TrustyTech",
        subtitle: "Зареєструйтеся на нашому сервісі за секунди!"
      },
      login: {
        title: "Ласкаво просимо на TrustyTech",
        subtitle: "Будь ласка, увійдіть в свій обліковий запис"
      },
      feedback: {
        title: "Ми завжди готові відповісти на ваші питання",
        subtitle: "та вислухати ваші пропозиції щодо поліпшення нашого сервісу"
      },
      default: {
        title: "Ласкаво просимо до TrustyTech",
        subtitle: "Будь ласка, увійдіть до свого облікового запису"
      }
    }
  },
  continents: {
    ASIA: "Азія",
    AFRICA: "Африка",
    NORTH_AMERICA: "Північна Америка",
    SOUTH_AMERICA: "Південна Америка",
    ANTARCTICA: "Антарктида",
    EUROPE: "Європа",
    AUSTRALIA: "Австралія"
  },
  howToBuy: {
    title: "Як купити ",
    proxy: "Проксі",
    subtitle:
      "Як придбати різноманітні типи проксі: центр обробки даних IPv4 і IPv6, Інтернет-провайдер, домашні та мобільні мережі 4G/5G:",
    proxySubtitle:
      "Щоб придбати високоякісні проксі IPv4, розташовані у величезній кількості мереж і підмереж з вибором відповідних місць",
    listTitle: "Послуги надаються за характеристиками в каталозі"
  },
  languages: {
    title: "Мовa",
    en: "Англійська",
    ru: "Російська",
    ua: "Українська"
  }
};
